import baseCountryConfig from "./baseConfig";
const MZ = {
  ...baseCountryConfig,
  countryName: "Mozambique",
  isoCode: "MZ",
  countryCode: "+258",
  iconName: "mozambique_flag",
  languages: [
    ...baseCountryConfig.languages,
    { name: "Portuguese", locale: "pt" }
  ],
  locale: "en-MZ",
  currencyCode: "MOZ",
  currencySymbol: "MZN",
  phoneRegExp: /^[0-9]{9}$/,
  phoneNoLength: 9,
  isCountryEnabled: false,
};

export default MZ;
