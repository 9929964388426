import React from "react";
import { Container, Row, Col, UncontrolledCollapse } from "reactstrap";
import { ENDPOINT } from "../constants";
import { connect } from "react-redux";
import axios from "axios";
import { redirectToRecentUrl } from "../actions/userActions";
import { recentBookingData } from "../actions/bookingActions";
import UserSidebar from "./UserDashboardSidebar";
import { css } from "@emotion/react";
import RingLoader from "react-spinners/RingLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
class cancelledBookings extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            bookings: [],
            loading: true,
        };
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        const config = {
            dataType: "json",
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props.Auth?.token,
            },
        };

        return await axios.get(`${ENDPOINT}/app/v2/bookings/${this.props.Auth?.user.userId}/${"cancelled"}/`,config).then(async response => {
            const { data = [], success } = response?.data;
            if (success) {
                let activeBookings = data
                activeBookings = (activeBookings || []).sort((a,b) => (b.booking_date > a.booking_date) ? 1 : ((a.booking_date > b.booking_date) ? -1 : 0));
                this.setState({
                    bookings: activeBookings || [],
                });
            }
        }).catch(()=>{
            
        }).finally(()=>{
            this.setState({
                loading: false
            });
        })
    }

    render() {
        var listItems = this.state.bookings.map(function(data, key) {
            return (
                <div className="block-content-2 style-3" key={key} style={{padding: '0px'}}>
                    <div>
                        <div className="root-outer p-4" id={'toggler' + data?.uuid }>
                            <div className="route-left">
                                <div className="route-heading"><h5> {data?.departure} </h5></div>
                                <div className="route-date">{data?.booking_date}</div>
                            </div>
                            <div className="route-right">
                                <div className="confirmed-text">
                                <div className="cancelled">cancelled</div>
                                    <div className="cross-icon"><i className="cross-ico fas fa-times"></i></div>
                                </div>
                            </div>
                            <div className="angle-down-1"><a href="#"><i className="fas fa-angle-down"></i></a></div>
                        </div>
                        <UncontrolledCollapse toggler={'#toggler' + data?.uuid }>
                            <div className="time-outer pt-4 pb-4">
                                <div className="time-inner p-3">
                                    <div className="time-left"><strong> {data?.departure} </strong></div>
                                    <div className="time-center"><div className="time-center-bus text-center"><i className="fas fa-bus"></i></div></div>
                                    <div className="time-right">{data?.arrival}</div>
                                </div>
                            </div>
                            <div className="seat-outer">
                                <div className="seat-inner p-5">
                                    <div className="seat-left">
                                        <div className="seat-detail-single">
                                            <div className="seat-detail-name">seat no:</div>
                                            <div className="seat-detail-description"><div className="kuma"> :</div> <div className="color-1"> {data?.seats.slice(1,-1)} </div></div>
                                        </div>
                                        <div className="seat-detail-single">
                                            <div className="seat-detail-name"> <b> Total Fare:</b> </div>
                                            <div className="seat-detail-description"> <div className="kuma"> <b>:</b></div> <div className="color-1"> <b>{data?.ticket_price}</b> </div> </div>
                                        </div>
                                    </div>
                                    <div className="seat-right">
                                        <div className="badge">
                                        <img src={require('../assets/images/files/cancelled.png')} alt="" />
                                        </div>
                                    </div>
                                    <div id={'toggler' + data?.uuid } className="angle-down-1"><a href="#"><i className="fas fa-angle-up"></i></a></div>
                                </div>
                            </div>
                        </UncontrolledCollapse>
                    </div>
                </div>
            );
        }, this);
      
        return (            
          <>
            <section id="content" className="page-single bg-grey">
                <div id="content-wrap">
                    <div className="section-flat">
                        <div className="section-content">
                            <Container>
                                <Row>
                                    <Col lg={3} md={12} className="mb-4">
                                        <UserSidebar />
                                    </Col >
                                    <Col lg={9} md={12} >
                                        <div className="content-main" style={{width: '100%'}}>
                                            {this.state.loading ? <RingLoader color="#02928b" loading={this.state.loading} css={override} size={150} /> :
                                            (this.state.bookings.length > 0 ? listItems :
                                                <div className="block-content-2 style-3">
                                                    <div>
                                                        <img style={{width: '78px'}} src={require('../assets/images/files/bus-stop.svg')} />
                                                        <p style={{fontSize: '30px', color: '#212a42', fontWeight: '600'}}>No cancelled Bookings Found</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </section>
          </>
        );
    }
}

const mapStateToProps = (state) =>{
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        recentBookingData: (data) =>{
            dispatch(recentBookingData(data))
        },
        redirectToRecentUrl: (data) => {
            dispatch(redirectToRecentUrl(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(cancelledBookings);