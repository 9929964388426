import React from "react";
import { Col, Container, Row } from "reactstrap";
import Footer from "../components/footers/Footer";
import Header from "../components/headers/Header";
import CustomNavbar from "../components/navbars/CustomNavbar";

class About extends React.Component {

    render() {
        return (
            <>
                <Header />
                <CustomNavbar />
                <div id="About-page" className="">
                    <div class="container-fluid">
                        <div class="main-banner">
                            <h2>ABOUT US</h2>
                        </div>
                    </div>
                    <div className="section-content">
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <div class="container-fluid secend-section">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-6 left-side-about">
                                                    <div class="about-heading">
                                                        <h2>About us</h2>
                                                        <p>
                                                            Sharearide is a service driven by Corrugated Minds, a young team, passionate about
                                                            transforming citizens’ day-to-day lives through the provision of solutions powered by
                                                            technology. Sharearide, a first of its kind in Botswana, is aimed at transforming access
                                                            and use of public transportation in Botswana by bringing ease and convenience to
                                                            travelers and transportation service providers. Our experienced team of professionals
                                                            are well versed in various platforms, languages and tools, and are committed to making
                                                            Sharearide the best public transportation platform in Africa.<br /><br />
                                                            Sharearide intends to not only provide easier transportation access to commuters, but to
                                                            promote local partnership as a way of boosting growth in local Service Providers.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 right-side-about">
                                                    <img alt="" src={require("../assets/images/files/sharearide-about.jpg")} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container-fluid third-section">
                                        <div class="container">
                                            <div class="row">
                                                <div class="pt-5 col-lg-6 left-side-icon mob-img">
                                                    <img alt="" className="" src={require("../assets/images/files/sharearide-about-busrank.png")} />
                                                </div>
                                                <div class="pt-5  col-lg-6 right-side-icon">
                                                    <div class="row icon-list">
                                                        <div class="col-md-2">
                                                            <i class="fa fa-bullseye" aria-hidden="true"></i>
                                                        </div>
                                                        <div class="col-md-10">
                                                            <h3>Mission</h3>
                                                            <p>
                                                                To consistently usher our people into the era of technology by changing how people view
                                                                technology and its role on human life.
                                                            </p>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <i class="fa fa-eye" aria-hidden="true"></i>
                                                        </div>
                                                        <div class="col-md-10">
                                                            <h3>Vision</h3>
                                                            <p>
                                                                To become the best architecture of modern services that looks at building and developing
                                                                solutions that tackles the world’s greatest issues to pave way for the future generations.
                                                            </p>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <i class="fa fa-key" aria-hidden="true"></i>
                                                        </div>
                                                        <div class="col-md-10">
                                                            <h3>Values</h3>
                                                            <p>We have a responsibility to usher our people into the era of technology.</p>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <i class="fas fa-tasks" aria-hidden="true"></i>
                                                        </div>
                                                        <div class="col-md-10">
                                                            <h3>Creativity</h3>
                                                            <p>Our creative team is made up of a creative director, web & graphic designers, photographers and video editors </p>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <i class="fas fa-cog" aria-hidden="true"></i>
                                                        </div>
                                                        <div class="col-md-10">
                                                            <h3>Innovate Solutions</h3>
                                                            <p>We have a desire to lead in the market by providing the best online solutions</p>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <i class="fa fa-university" aria-hidden="true"></i>
                                                        </div>
                                                        <div class="col-md-10">
                                                            <h3>Research & Innovation</h3>
                                                            <p>
                                                                We are constantly working to better our services, which is why we have set up a department
                                                                dedicated to research and development.
                                                            </p>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <i class="fas fa-briefcase" aria-hidden="true"></i>
                                                        </div>
                                                        <div class="col-md-10">
                                                            <h3>Passion & Hard work</h3>
                                                            <p>Our team is driven by their passion for web development and quality service provision.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default About;
