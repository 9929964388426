import React from "react";
import { Col, Container, Row, Input } from "reactstrap";
import { connect } from "react-redux";

class ThankYou extends React.Component {

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    render(){
        return (
            <>
                <section className="page-single bg-grey">
                    <div id="content-wrap">
                        <div className="section-flat">
                            <div className="section-content">
                                <Container>
                                        <div className="content-main">
                                            <div className="block-content-2 style-3">
                                                <Row>
                                                    <Col md={12}>
                                                        <h1 className="txt-center thnx-heading">Thank You!</h1>
                                                        <p style={{marginBottom: '30px'}} className="txt-center">For booking on ShareARide</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <p className="txt-center">
                                                            <Input
                                                            type="submit"
                                                            className="form-control btn"
                                                            onClick={() =>{ this.props.history.push("/user/bookings");}}
                                                            value="Bookings"/>
                                                        </p>                                                         
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                </Container>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = (state) =>{
    return {
        ...state
    }
}

export default connect(mapStateToProps, null)(ThankYou);