import React, {createRef} from "react";
import {
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import Footer from "../components/footers/Footer";
import Header from "../components/headers/Header";
import CustomNavbar from "../components/navbars/CustomNavbar";
import alertify from "alertifyjs";
import axios from "axios";
import { ENDPOINT } from "../constants";

class ContactUs extends React.Component {
  constructor(props){
    super(props);

    this.nameInput = createRef();
    this.emailInput = createRef();
    this.messageInput = createRef();
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }
  handleSubmit = () =>{
    
    var email_el = document.getElementById('email');
    var name_el = document.getElementById('name');
    var message_el = document.getElementById('message');

    if(this.nameInput.current.value === ""){
      alertify.notify('Name can not be empty', 'custom', 2, function(){});
      name_el.style.border = "1px solid #ff8476";
      return;
    }else{
      name_el.style.border = "1px solid #ddd";
    }

    if(!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.emailInput.current.value)){
      if(this.emailInput.current.value === ""){
        alertify.notify('Email cannot be empty', 'custom', 2, function(){});
        email_el.style.border = "1px solid #ff8476";
        return;
      }else{
        alertify.notify('Invalid email', 'custom', 2, function(){});
        email_el.style.border = "1px solid #ff8476";
        return;
      }
    }else{
      email_el.style.border = "1px solid #ddd";
    }

    if(this.messageInput.current.value === ""){
      alertify.notify('Name can not be empty', 'custom', 2, function(){});
      message_el.style.border = "1px solid #ff8476";
      return;
    }else{
      message_el.style.border = "1px solid #ddd";
    }

    const config = {
      headers: {
        'content-type': 'application/json',
      }
    };
    var data = {
      name: this.nameInput.current.value,
      email: this.emailInput.current.value,
      message: this.messageInput.current.value
    };
    axios.post(ENDPOINT+'/post-contact',data,config)
    .then((response) => {
        if(response.data.code === 404){
          alertify.notify(response.data.message, 'custom', 2, function(){});
        }

        if(response.data.code === 200){
          alertify.notify('Message has been sent', 'customsuccess', 2, function(){});
        }
    }).catch((error) => {
        alertify.notify('Something went wrong while sending message', 'custom', 2, function(){});
    });
  }

  render() {
    return (
      <>
        <Header />
        <CustomNavbar />
<div className="contact-container-outer">
        <Container >
          
          <Row className="">
            <Col md={6}>
            <div className="contact-form-heading">
                <h2 className="mt-4">Request More Information</h2>
              </div>
              <p className="request">
                Please provide us the required information so we <br /> best
                assist you.
              </p>
            </Col>

            <Col md={6}>
              <div className="contact-form-outer">
              <div className="contact-form-heading">
                <h2 className="mt-3">Contact Us</h2>
              </div>
            <Form>
                <FormGroup>
                  <Label for="Name">Name</Label>
                  <Input
                    type="text"
                    name="Name"
                    id="name"
                    placeholder="Enter your Name..."
                    innerRef={this.nameInput}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="Email">Email</Label>
                  <Input
                    type="email"
                    name="Email"
                    id="email"
                    placeholder="Enter your Email..."
                    innerRef={this.emailInput}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="exampleText">Message</Label>
                  <Input
                    placeholder="Enter you Message..."
                    type="textarea"
                    name="Subject"
                    id="message"
                    innerRef={this.messageInput}
                  />
                </FormGroup>
                <Button onClick={this.handleSubmit} className="mt-3 submit-1">Submit</Button>
              </Form>
              </div>
            </Col>
          </Row>
        </Container>
        </div>

        <Footer />
      </>
    );
  }
}

export default ContactUs;
