import React from "react";
import {Button,  Container, Row, Col, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { updateBusBookingSeats, updateBusBookingData } from "../actions/bookingActions";
import alertify from "alertifyjs";
import moment from 'moment';
import { firebaseDatabase } from "../config/firebaseConfig";
import { ref, onValue, child, get, update} from "firebase/database";

class Booking extends React.Component {
  constructor(props){
    super(props);
    delete this.props.global?.selectedBusDetails?.bus["Facilities"];
    this.state = {
      loading: true,
      seats: null,
      ticketPrice: 0,
      total_seats: 16,
      selected_seats: [],
      passengers: [],
      seating_layout: '2-2',
      last_seat: 1,
      search_date: this.props.global?.search_date,
      booked_seats: [],
      default_route: { value: 0, label: this.props.global?.search_from.label + ' - ' + this.props.global?.search_to.label},
      default_route_id: null,
      sub_routes: null,
      bus_sub_route_id: null,
      sub_routes_loading: true,
      selected_sub_route_id: null,
      departure_sequence_number: 1,
      bus: this.props.global?.selectedBusDetails,
      availableBusSubRoutes: [],
      interval: null,

      userSurname: this.props?.Auth?.user?.lastName,
      userForenames: this.props?.Auth?.user?.firstName,
      userMobileNumber: this.props?.Auth?.user?.phone,
      userEmail: this.props?.Auth?.user?.email,
      bookingDate: new Date(this.props.global?.search_date),
      busPrice: this.props.global?.selectedBusDetails?.routeDetails?.busSubRoute[0]?.busSubRoute?.fare,
      departureSequence: this.props.global?.selectedBusDetails?.routeDetails?.busSubRoute[0]?.subRoute?.departureSequenceNumber,
      arrivalSequence: this.props.global?.selectedBusDetails?.routeDetails?.busSubRoute[0]?.subRoute?.arrivalSequenceNumber,
              
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.handleRouteChange();

    /** Check if there are reserved seats and release them if time expired */
    if (!this.state.interval) {
      this.setState({
        interval: setInterval(this.timerTrigger, 300000)
      })
    }
  }

  getValidUuid = (ids)=>{
    const checkReferences = [
      "arrivalSequence",
      "departureSequence",
      "endSequence",
      "expectedReleaseTimestamp",
      "isRouteDefault",
      "names",
      "reservedBy",
      "startSequence",
      "status"
    ]
    if(Array.isArray(ids)){
      let idChecked = "";
      for(let i = 0; i < ids.length; i++){
        if (!checkReferences.includes(ids[i])){
          idChecked = ids[i];
          break;
        }
      }
      return idChecked;
    }
    return "";
  }

  timerTrigger = ()=>{
    const bookedSeatsStatus = (this.state.booked_seats || []).map(seat=> {  
      const payload = {
        id: seat?.id,
        reservedBy: seat?.creator,
        isRouteDefault: seat?.isRouteDefault,
        startSequence: seat?.startSequence,
        endSequence: seat?.endSequence,
        status: seat?.status,
        expectedReleaseTimestamp: seat?.expectedReleaseTimestamp,
        names: seat?.names,
        departureSequence: seat?.departureSequence,
        arrivalSequence: seat?.arrivalSequence,
      }
      payload[`${seat?.routeId}`] = {
        departureTime: seat?.departureTime,
        arrivalTime: seat?.arrivalTime
      }
      const timespan = moment.duration(
        moment(moment(seat?.expectedReleaseTimestamp).format('YYYY-MM-DD HH:mm').valueOf())
        .diff(moment(moment().format('YYYY-MM-DD HH:mm').valueOf()))).asSeconds()
      if((timespan < 1) && (seat.status !== "BOOKED")){
        return {
          ...payload,
          status: "RELEASED"
        }
      }
      return payload
    });
    for(let i = 0; i < bookedSeatsStatus.length; i++){
      const seat = bookedSeatsStatus[i];
      if(seat.status === "RELEASED"){
        this.reserveSelectedSeat(seat.id, seat)
      }
    }
  }

  reserveSelectedSeat(seatNumber, payload) {
    const timestamp = moment(this.state.search_date).format("YYYY-MM-DD").valueOf()
    const reference = `bookings/${timestamp}/${this.props.global?.selectedBusDetails?.bus?.uuid}/${seatNumber}`;
    try{
      update(ref(firebaseDatabase, reference), payload);
    }catch(_e){
    }  
  }

  handleRouteChange = async () =>{
    if(this.state.bookingDate){
      const timestamp = moment(this.state.bookingDate).format("YYYY-MM-DD").valueOf();  
      const reference = "bookings/".concat(timestamp).concat("/").concat(this.props.global?.selectedBusDetails?.bus?.uuid);
      const seatReference = ref(firebaseDatabase, reference);
      onValue(seatReference, (snapshot) => {
        const data = snapshot.val();
        if(data === null){
          this.setState({
            loading: false,
          });

          this.handleSeatArrangements([]);
        }else{
          //Seat has been reserved
          let allBookedSeats = []
          const keys = Object.keys(data);
          for(let i = 0; i < keys.length; i++){
            const seatNo = keys[i]
            const seat = data[seatNo];
            const innerKeys = Object.keys(seat); 
            const routeId = this.getValidUuid(innerKeys);
            allBookedSeats.push({
              id: keys[i],
              status: seat?.status,
              creator: seat?.reservedBy,
              expectedReleaseTimestamp: seat?.expectedReleaseTimestamp,
              isRouteDefault: seat?.isRouteDefault,
              startSequence: seat?.startSequence,
              endSequence: seat?.endSequence,
              routeId: routeId,
              departureTime: seat[routeId]?.departureTime,
              arrivalTime: seat[routeId]?.arrivalTime,
              names: seat?.names,
              departureSequence: seat?.departureSequence,
              arrivalSequence: seat?.arrivalSequence
            });
          }

          allBookedSeats = allBookedSeats?.filter(seat=> {
            if(seat?.status !== "RELEASED"){
              const isSameDeparture = seat?.departureSequence === this.state.departureSequence;
              
              if(isSameDeparture){
                return true;
              }
              if(this.state.arrivalSequence > seat?.departureSequence){
                if(this.state.departureSequence === seat?.arrivalSequence){
                  return false;
                }
                return true;
              }
            }
            return false;
          });
          const userSelectedSeats = (allBookedSeats || []).filter(seat=> seat?.creator === this.props?.Auth?.user?.userId).map(seat=>{ 
            return {
              "id": seat.id,
              "uuid": null,
              "names": seat?.names,
              "seatNumber": seat.id,
              "status": seat?.status || "ACTIVE"
            }
          });
          const fare = parseInt(this.state.busPrice) * userSelectedSeats.filter(seat => seat?.status !== "BOOKED").length;
          this.setState({
            booked_seats: allBookedSeats,
            loading: false,
            selected_seats: userSelectedSeats.map(seat => { return seat.id}),
            passengers: userSelectedSeats.reduce((ac,a) => ({...ac,[a.id]: a}),{}),
            ticketPrice: fare
          });
          this.props.updateBusBookingSeats({
            seats: userSelectedSeats.map(seat => { return seat.id}),
            base_fare: parseInt(this.state.busPrice),
            fare: fare,
          });
          this.handleSeatArrangements(allBookedSeats);
        }
      });           
    } else{
      this.setState({
        loading: false,
      });
    }
  }

  handleSeatArrangements(allBookedSeats){
    const rowAffected = document.getElementById('bus-body')
    if(rowAffected){
      if(rowAffected.childElementCount > 0){
        rowAffected.replaceChildren()
      }
    }

    const busLayout = this.props.global?.selectedBusDetails?.bus?.layout;
    
    if(busLayout !== null){
      var seats_per_row = busLayout?.split("/");
      seats_per_row = parseInt(seats_per_row[0])+parseInt(seats_per_row[1]);            
      var empty_at = busLayout?.split("/");
      empty_at = parseInt(empty_at[0]) + 1;        
      var row_length = seats_per_row + 1;
      var seat_rows = this.props.global?.selectedBusDetails?.bus?.totalSeats / seats_per_row;
      var seating_layout_arr = [];
      var arr = [];
      for(var j = 1; j <= row_length; j++){
        if(j === row_length){
          arr.push({
            id: 'driver',
            value: 'driver'
          });
        }else{
          arr.push({
            id: 'empty',
            value: 'empty'
          });
        }
      }
      seating_layout_arr.push(arr);        
      var alphabets = ['','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];        
      for(var i = 1; i <= seat_rows; i++){
        arr = [];
        var num = 1;
        for(var k = 1; k <= row_length; k++){
          if(i === seat_rows && String(this.props.global?.selectedBusDetails?.bus?.lastseat).toLowerCase() === "true"){
            arr.push({
              id: alphabets[i]+num,
              value: alphabets[i]+num
            });
            num++;
          }else{
            if(k === empty_at){
              arr.push({
                id: 'empty',
                value: 'empty'
              });
            }else{
              arr.push({
                id: alphabets[i]+num,
                value: alphabets[i]+num
              });
              num++;
            }
          }
          
        }
        seating_layout_arr.push(arr);
      }       
      
      for(var x = 0; x < seating_layout_arr.length; x++){
        const tr = document.createElement('TR');
        tr.setAttribute('id', "bus-seats-layout");
        const rowLayout = seating_layout_arr[x];
        if(x === 0){
          for(var n = 0; n < rowLayout.length; n++){
            const td = document.createElement('TD');
            if(rowLayout[n].id === 'empty'){
              tr.appendChild(td);
            }else{              
              const img = document.createElement('IMG');
              img.src = require('../assets/images/files/bus/steering-wheel.png');
              td.appendChild(img);
              tr.appendChild(td);
            }
          }
        }else{
          for(var h = 0; h < rowLayout.length; h++){
            const td = document.createElement('TD');
            if(rowLayout[h].id === 'empty'){
              tr.appendChild(document.createElement("TD"));
            }else{
              const bookedSeatsStatus = (allBookedSeats || []).filter(seat=> seat.id === rowLayout[h].id)
              if((allBookedSeats || []).map(seat=>{ return seat.id}).includes(rowLayout[h].id)){
                if(bookedSeatsStatus.length > 0){
                  if(bookedSeatsStatus[0].status === "RESERVED"){
                    td.setAttribute('id','td' + rowLayout[h].id);
                    var img = document.createElement('IMG');
                    img.src = require('../assets/images/files/bus/seat-available.png');
                    img.setAttribute('id', rowLayout[h].id);
                    img.addEventListener('click', this.handleSeatSelection);
                    img.style.display = 'none';
                    td.appendChild(img);

                    var selectedimg = document.createElement('IMG');
                    selectedimg.src = require('../assets/images/files/bus/seat-selected.png');
                    selectedimg.setAttribute('id', rowLayout[h].id);
                    selectedimg.classList.add("selection" + rowLayout[h].id);
                    selectedimg.style.display = 'block';
                    selectedimg.addEventListener('click', this.handleSeatSelection);
                    td.appendChild(selectedimg);
                    tr.appendChild(td);
                  } else  if(bookedSeatsStatus[0].status === "RELEASED"){
                    td.setAttribute('id','td' + rowLayout[h].id);
                    var img = document.createElement('IMG');
                    img.src = require('../assets/images/files/bus/seat-available.png');
                    img.setAttribute('id', rowLayout[h].id);
                    img.addEventListener('click', this.handleSeatSelection);
                    img.style.display = 'block';
                    td.appendChild(img);

                    var selectedimg = document.createElement('IMG');
                    selectedimg.src = require('../assets/images/files/bus/seat-selected.png');
                    selectedimg.setAttribute('id', rowLayout[h].id);
                    selectedimg.classList.add("selection" + rowLayout[h].id);
                    selectedimg.style.display = 'none';
                    selectedimg.addEventListener('click', this.handleSeatSelection);
                    td.appendChild(selectedimg);
                    tr.appendChild(td);
                  } else{
                    td.setAttribute('id','td' + rowLayout[h].id);
                    var img = document.createElement('IMG');
                    img.src = require('../assets/images/files/bus/seat-booked.png');
                    img.setAttribute('id', rowLayout[h].id);
                    img.style.display = 'block';
                    td.appendChild(img);
                    tr.appendChild(td);
                  }
                }else{
                  td.setAttribute('id','td' + rowLayout[h].id);
                  var img = document.createElement('IMG');
                  img.src = require('../assets/images/files/bus/seat-booked.png');
                  img.setAttribute('id', rowLayout[h].id);
                  img.style.display = 'block';
                  td.appendChild(img);
                  tr.appendChild(td);
                }
              }else{
                td.setAttribute('id','td' + rowLayout[h].id);
                var img = document.createElement('IMG');
                img.src = require('../assets/images/files/bus/seat-available.png');
                img.setAttribute('id', rowLayout[h].id);
                img.addEventListener('click', this.handleSeatSelection);
                img.style.display = 'block';
                td.appendChild(img);

                var selectedimg = document.createElement('IMG');
                selectedimg.src = require('../assets/images/files/bus/seat-selected.png');
                selectedimg.setAttribute('id', rowLayout[h].id);
                selectedimg.classList.add("selection" + rowLayout[h].id);
                selectedimg.style.display = 'none';
                selectedimg.addEventListener('click', this.handleSeatSelection);
                td.appendChild(selectedimg);
                tr.appendChild(td);
              }
            }
          }
        }
        if(rowAffected){
          document.getElementById('bus-body').appendChild(tr);
        }
      }
    }
  }

  handleSeatSelection = (e) =>{
    var seats_el = document.getElementById('seats');
    seats_el.style.border = "1px solid #ddd";
    const base_fare = parseInt(this.state.busPrice);
    const isRouteDefault = this.props.global?.selectedBusDetails?.routeDetails?.busSubRoute[0]?.subRoute?.isDefault;
    const startSequence = this.props.global?.selectedBusDetails?.routeDetails?.busSubRoute[0]?.subRoute?.starting;
    const endSequence = this.props.global?.selectedBusDetails?.routeDetails?.busSubRoute[0]?.subRoute?.ending;
    let payload = {
      reservedBy: this.props?.Auth?.user?.userId,
      isRouteDefault: isRouteDefault,
      startSequence: startSequence,
      endSequence: endSequence,
      departureSequence: this.state.departureSequence,
      arrivalSequence: this.state.arrivalSequence,
    }
    payload[`${this.props.global?.selectedBusDetails?.routeDetails?.busSubRoute[0]?.busSubRoute?.uuid}`] = {
      departureTime: this.props.global?.selectedBusDetails?.routeDetails?.busRoute?.departure,
      arrivalTime: this.props.global?.selectedBusDetails?.routeDetails?.busRoute?.arrival
    }
    if(this.state.selected_seats.indexOf(e.target.id) > -1){
      var arr = this.state.selected_seats;
      for( var i = 0; i < arr.length; i++){ 
        if ( arr[i] === e.target.id){
          arr.splice(i, 1); 
          i--;
        }
      }
      arr.sort();
      this.setState({
        selected_seats: arr
      });
      
      document.getElementById(e.target.id).style.display = 'block';
      document.getElementsByClassName("selection"+e.target.id)[0].style.display = 'none';   
      const fare = base_fare * this.state.selected_seats.length;
      var data = {
        seats: this.state.selected_seats,
        base_fare: base_fare,
        fare: fare,
      };
      this.props.updateBusBookingSeats(data);
      this.setState({
        ticketPrice: fare,
      });
      /**Update realtime database for the removed seat */
      const bookedSeatsStatus = (this.state.booked_seats || []).filter(seat=> seat.id === e.target.id);
      let statusSeat = "RELEASED";
      if(bookedSeatsStatus.length > 0){
        if(bookedSeatsStatus[0].status === "RESERVED"){
          statusSeat = "RELEASED";
        } else{
          statusSeat = "RESERVED";
        }
      }

      payload = {
        ...payload,
        status: statusSeat,
        expectedReleaseTimestamp: moment().add(20, "minute").valueOf(),
        names: "",
      }      
      this.reserveSelectedSeat(e.target.id, payload)
    }else{
      if(this.state.selected_seats.length >= 3){
        alertify.error(`You have reached maximum seats allowed for this transaction`);
        return;
      }
      var arr = this.state.selected_seats;
      arr.push(e.target.id);
      arr.sort();
      this.setState({
        selected_seats: arr,
      });

      document.getElementsByClassName("selection" + e.target.id)[0].style.display = 'block';
      document.getElementById(e.target.id).style.display = 'none';

      var fare = base_fare * this.state.selected_seats.length;
      var data = {
        seats: this.state.selected_seats,
        base_fare: base_fare,
        fare: fare,
      };
      this.props.updateBusBookingSeats(data);
      this.setState({
        ticketPrice: fare,
      });
      /**Update realtime database for the removed seat */
      let statusSeat = "RESERVED";
      payload = {
        ...payload,
        status: statusSeat,
        expectedReleaseTimestamp: moment().add(20, "minute").valueOf(),
        names: "",
      }
      this.reserveSelectedSeat(e.target.id, payload)
    }

    let _newPassengers = {};
    let _passengers = this.state.passengers;
    let _blankPassenger = {
      "id": `${e.target.id}`,
      "uuid": null,
      "names": "",
      "seatNumber": `${e.target.id}`,
      "status": "ACTIVE"
    };
    let _selected_seats = this.state.selected_seats;

    _selected_seats.map((idx)=>{
      if(!Object.keys(_passengers).includes(idx)){
        Object.assign(_passengers, {[idx]: _blankPassenger});
      }      
    });

    Object.keys(_passengers).map((idx)=>{
      if(_selected_seats.includes(idx)){
        Object.assign(_newPassengers, {[idx]: _passengers[idx]});
      }
    });

    let _tempPassengers = Object.entries(_newPassengers).sort(([,a],[,b]) => a-b).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    this.setState({
      passengers: _tempPassengers,
    });
  }

  handleSearchAgain = () =>{
    this.props.history.push("/search");
  }
  
  isDailyRequestExceeded (request){
    if(request?.requestTimeStamp > moment().subtract(24, 'hours').unix()){            
      return (request.requestCount >= 3)
    }
    return false        
  }
  isWeeklyRequestExceeded (request){
    if(request?.requestTimeStamp > moment().subtract(7, 'days').unix()){
      return (request.requestCount >= 7)
    }
    return false;
  }
  isMonthlyRequestExceeded (request){
    if(request?.requestTimeStamp > moment().subtract(28, 'days').unix()){
      return (request.requestCount >= 13)
    }
    return false;
  }
  isYearlyRequestExceeded (request){
    if(request?.requestTimeStamp > moment().subtract(12, 'months').unix()){
      return (request.requestCount >= 20)
    }
    return false;
  }

  processBookingPolicies (dailyRequest, weeklyRequest, monthlyRequest, yearlyRequest){
    if(this.isDailyRequestExceeded(dailyRequest)){
        return "Your account has been suspended, you can not make bookings as you have exceeded your daily limit of ticket cancellation";
    } else if(this.isWeeklyRequestExceeded(weeklyRequest)){
      return "Your account has been suspended, you can not make bookings as you have exceeded your weekly limit of ticket cancellation";
    }  else if(this.isMonthlyRequestExceeded(monthlyRequest)){
      return "Your account has been suspended, you can not make bookings as you have exceeded your monthly limit of ticket cancellation";
    } else if(this.isYearlyRequestExceeded(yearlyRequest)){
      return "Your account has been suspended, you can not make bookings as you have exceeded your yearly limit of ticket cancellation";
    } else{
      return "PROCEED";
    }
  }

  handlePay = ()=>{
    /**Check ticket policy rules */
    const reference = "bookings-policies/".concat(this.props.Auth?.user.userId);
    const bookingsReference = ref(firebaseDatabase);

    get(child(bookingsReference, reference)).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        if(data === null){
          this.handleBookingProcessCheck();
        }else{
          const dailyRequest = data?.dailyRequest;
          const weeklyRequest = data?.weeklyRequest;
          const monthlyRequest = data?.monthlyRequest;
          const yearlyRequest = data?.yearlyRequest;

          const isDailyRequest = (dailyRequest === null || (Object.keys(dailyRequest).length > 0));
          const isWeeklyRequest = (weeklyRequest === null || (Object.keys(weeklyRequest).length > 0));
          const isMonthlyRequest = (monthlyRequest === null || (Object.keys(monthlyRequest).length > 0));
          const isYearlyRequest = (yearlyRequest === null || (Object.keys(yearlyRequest).length > 0));

          const isPolicyCompliant = (isDailyRequest && isWeeklyRequest && isMonthlyRequest && isYearlyRequest);

          if(isPolicyCompliant){
            this.handleBookingProcessCheck();
          } else{
            let errorMsg = this.processBookingPolicies(dailyRequest, weeklyRequest, monthlyRequest, yearlyRequest);
            if(errorMsg === "PROCEED"){
              this.handleBookingProcessCheck();
            } else {
              alertify.error(errorMsg);
            }
          }
        } 
      } else {
        this.handleBookingProcessCheck();
      }
    }).catch((_error) => {
      alertify.error("Application error when processing your booking")
    });  
  }

  handleBookingProcessCheck = () =>{
    if (this.state.interval) {
      clearInterval(this.state.interval);
      this.setState({
        interval: null
      })
    }
    
    let fname_el = document.getElementById('cbFirstName');
    let lname_el = document.getElementById('cbLastName');
    let email_el = document.getElementById('cbEmail');
    let phone_el = document.getElementById('cbMobile');
    let seats_el = document.getElementById('seats');

    fname_el.style.border = "1px solid #ddd";
    lname_el.style.border = "1px solid #ddd";
    email_el.style.border = "1px solid #ddd";
    phone_el.style.border = "1px solid #ddd";
    seats_el.style.border = "1px solid #ddd";    

    if(!this.state.selected_seats.length > 0){
      alertify.notify('Please choose a seat to continue', 'custom', 2, function(){});
      seats_el.style.border = "1px solid #ff8476";
      return;
    }else{
      seats_el.style.border = "1px solid #ddd";
    }

    let seats = "";
    let _passengerError = false;
    let _message = '';
    
    let bookedPassengers = [];
    const keys = Object.keys(this.state.passengers);
    for(let i = 0; i < keys.length; i++){
      if(this.state.passengers[keys[i]]?.status !== "BOOKED"){
        bookedPassengers.push({
          ...this.state.passengers[keys[i]],
          id: null,
          status: "active"
        });
        seats = seats.concat(",").concat(this.state.passengers[keys[i]]?.seatNumber);
      }
    }

    if(bookedPassengers.length < 1){
      alertify.notify("No passengers added, please provide at-least 1 passenger", 'custom', 2, function(){});
      return; 
    }

    bookedPassengers.map((_passengers)=>{
      let  _passenger_el = document.getElementById('passenger_'+_passengers.seatNumber);
      if(!/^[A-Za-z][A-Za-z\s]*$/.test(_passengers?.names)){
        _passengerError = true;
        if(_passengers?.names === ""){
          _message = 'Passenger name can not be empty';
        }else{
          _message = 'Passenger name should only contain alphabets';
        }
        _passenger_el.style.border = "1px solid #ff8476";
        return;
      }else{
        _passenger_el.style.border = "1px solid #ddd";
      }
    });

    if(_passengerError){ 
      alertify.notify(_message, 'custom', 2, function(){});
      return; 
    }

    const base_fare = parseInt(this.state?.busPrice);
    const fare = base_fare * bookedPassengers.length;
    this.props.updateBusBookingSeats({
      seats: seats.trim().replace(/^,/,''),
      base_fare: base_fare,
      fare: fare,
    });
    const data = {
      booking_seats: seats.trim().replace(/^,/,''),
      booking_passengers: bookedPassengers,
    };
   
    this.props.updateBusBookingData(data);

    this.props.history.push("/user/booking-overview");
  }

  handlePassengerChange = (idx) => (e) => {
    const _passengers = this.state.passengers;
    if(!_passengers.hasOwnProperty(idx)) return;
    _passengers[idx].names = e.target.value;
    this.setState({ passengers: _passengers });

    const seat = this.state.booked_seats.filter(seat => seat.id === idx)[0];
    const payload = {
      id: seat?.id,
      reservedBy: seat?.creator,
      isRouteDefault: seat?.isRouteDefault,
      startSequence: seat?.startSequence,
      endSequence: seat?.endSequence,
      status: seat?.status,
      expectedReleaseTimestamp: seat?.expectedReleaseTimestamp,
      names: e.target?.value,
      departureSequence: seat?.departureSequence,
      arrivalSequence: seat?.arrivalSequence,
    }
    payload[`${seat?.routeId}`] = {
      departureTime: seat?.departureTime,
      arrivalTime: seat?.arrivalTime
    }
    this.reserveSelectedSeat(payload.id, payload)
  }

  render() {
    return (
      <>
        <section id="content" className="page-single bg-grey">
          <div id="content-wrap">
            <div className="section-flat">
              <div className="section-content">
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className="page-single-content">
                        <Row>
                          <Col md={8}>
                            <div className="content-main">
                              <div className="block-content-2 style-3">
                                <div className="block-title wide-bottom">
                                  <Row>                                  
                                    <Col md={11}>
                                      <h3>Personal Details</h3>
                                    </Col>
                                    <Col md={1}>
                                      <Button type="submit" className="form-control icon" onClick={this.handleSearchAgain} > <i className="fas fa-undo"></i> </Button>
                                    </Col>
                                  </Row>
                                </div>
                                <h4>Book Bus Seats</h4>
                                <Row>
                                  <Col md={12}>
                                    <Form id="form-car-booking-confirmation">
                                      <div className="form-content">
                                        <FormGroup>
                                          <div className="fields-row fields-2">
                                            <div className="box-field">
                                              <Label htmlFor="cbFirstName">
                                                First Name
                                              </Label>
                                              <Input
                                                type="text"
                                                name="cbFirstName"
                                                id="cbFirstName"
                                                className="form-control"
                                                defaultValue={this.state?.userForenames}
                                                readOnly
                                              />
                                            </div>
                                            <div className="box-field">
                                              <Label htmlFor="cbLastName">
                                                Last Name
                                              </Label>
                                              <Input
                                                type="text"
                                                name="cbLastName"
                                                id="cbLastName"
                                                className="form-control"
                                                defaultValue={this.state?.userSurname}
                                                readOnly
                                              />
                                            </div>
                                          </div>
                                        </FormGroup>
                                        <FormGroup>
                                          <div className="fields-row fields-2">
                                            <div className="box-field">
                                              <Label htmlFor="cbEmail">Email</Label>
                                              <Input
                                                type="text"
                                                name="cbEmail"
                                                id="cbEmail"
                                                className="form-control"
                                                placeholder=""
                                                defaultValue = {this.state?.userEmail}
                                                disabled
                                              />
                                            </div>
                                            <div className="box-field">
                                              <Label htmlFor="cbMobile">
                                                Mobile
                                              </Label>
                                              <Input
                                                type="text"
                                                name="cbMobile"
                                                id="cbMobile"
                                                className="form-control"
                                                defaultValue={this.state?.userMobileNumber}
                                                readOnly
                                              />
                                            </div>
                                          </div>
                                        </FormGroup>
                                        <FormGroup>
                                          <div className="fields-row fields-2">
                                            <div className="box-field">
                                              <Label htmlFor="cbEmail">Date</Label>
                                              <DatePicker
                                                readOnly={true}
                                                selected={this.state?.bookingDate}
                                                minDate={new Date()}
                                                className="riding-date"
                                              />
                                            </div>
                                            <div className="box-field">
                                              <Label htmlFor="cbMobile">
                                                Seat Number
                                              </Label>
                                              <Input
                                                type="text"
                                                name="cbSeats"
                                                id="seats"
                                                className="form-control"
                                                placeholder=""
                                                disabled
                                                defaultValue={this.state.selected_seats}
                                                readOnly={true}
                                              />
                                            </div>
                                          </div>
                                        </FormGroup>
                                        <FormGroup>
                                          <div className="fields-row fields-2">
                                            <div className="box-field">
                                              <Label htmlFor="cbRoute">Route</Label>
                                              <Input
                                                type="text"
                                                name="cbRoute"
                                                id="root"
                                                className="form-control"
                                                placeholder=""
                                                disabled
                                                defaultValue={this.state.default_route}
                                                readOnly={true}
                                                value={this.state.default_route?.label}
                                              />
                                            </div>
                                            <div className="box-field"></div>
                                          </div>
                                        </FormGroup>
                                      </div>
                                    </Form>
                                  </Col>
                                  <Col md={12}>
                                      <Row>
                                        <Col style={{marginTop: '30px'}} md={6}>
                                          <Row>
                                            <Col sm={4}><div><span><img style={{width: '30px'}} src={require('../assets/images/files/bus/seat-booked.png')} /></span><span style={{color: '#9B9B9B'}}> Booked</span></div></Col>
                                            <Col sm={4}><div><span><img style={{width: '30px'}} src={require('../assets/images/files/bus/seat-available.png')} /></span><span style={{color: '#000000'}}> Available</span></div></Col>
                                            <Col sm={4}><div><span><img style={{width: '30px'}} src={require('../assets/images/files/bus/seat-selected.png')} /></span><span style={{color: '#02928b'}}> Selected</span></div></Col>
                                          </Row>
                                        </Col>
                                        <Col md={6}>
                                        <p>Please know that a seat will be reserved only for 20 minutes then it will be release should you not make any booking within the time frame</p>
                                        </Col>                                        
                                      </Row>
                                  </Col>
                                  <Col md={6}>
                                    <div style={{marginTop: '14px',border: '2px solid #999', padding: '10px', borderRadius: '15px'}}>
                                      {this.state.loading? <Spinner color="primary" /> : 
                                        <table className="bus-layout">
                                          <tbody id="bus-body"></tbody>
                                        </table>
                                      }
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="py-3">
                                      {Object.keys(this.state.passengers).map((idx) => (
                                      <div className="form-group" key={`holder_${idx}`}>
                                        <div className="box-field input-group" >
                                          <div className="input-group-prepend">
                                            <span className="input-group-text" style={{background:"#02928b",color:"#fff"}}>{ idx }</span>
                                          </div>
                                          <Input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Passenger Name"
                                              value={this.state.passengers[idx].names}
                                              onChange={this.handlePassengerChange(idx)}
                                              name={ 'passengers['+ idx +'][names]' }
                                              key={ 'passengers_' + idx }
                                              id={ 'passenger_' + idx }
                                              required
                                              readOnly={this.state.passengers[idx].status === "BOOKED"}
                                            />
                                        </div>
                                      </div>))}
                                    </div>
                                  </Col>
                                </Row>
                                <div className="divider-50"></div>
                                <div className="summary-booking">
                                  <h5>
                                    Please read the terms and policy before
                                    proceed to booking.
                                  </h5>
                                  <p>
                                    Car hire, 24-hour front desk, Express
                                    check-in/check-out, Currency exchange,
                                    Ticket service, Luggage storage, Concierge
                                    service, Babysitting/child services,
                                    Laundry, VIP facilities.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="sidebar">
                              <ul className="list-box-sidebar">
                                <li>
                                  <h3 className="box-title">Booking Summary</h3>
                                  <div className="car-title">
                                    {
                                      this.props.global?.selectedBusDetails?.bus?.image ? <img src={new Buffer.from(this.props.global?.selectedBusDetails?.bus?.image).toString("ascii")} alt="" />:
                                      <img src={require("../assets/images/files/bus.png")} alt="" />
                                    }
                                    <div className="content">
                                      <h5 className="title">
                                        {this.props.global?.selectedBusDetails?.owner?.name}
                                      </h5>
                                      <p>
                                        <span>Reg # {this.props.global?.selectedBusDetails?.bus?.regNo}</span>
                                      </p>                                      
                                    </div>
                                  </div>
                                  <ul className="list-booking-details">
                                    <li>
                                      <strong> Departure</strong>
                                      <span>{this.props.global?.search_from.label}</span>
                                      <span>{this.props.global?.selectedBusDetails?.routeDetails?.busRoute?.departure}</span>
                                    </li>
                                    <li>
                                      <strong>Arrival</strong>
                                      <span>{this.props.global?.search_to?.label}</span>
                                      <span>{this.props.global?.selectedBusDetails?.routeDetails?.busRoute?.arrival}</span>
                                    </li>
                                    <li>
                                      <div className="booking-cost">
                                        <h3> Subtotal:{" "} <span className="colored">BWP {this.state.ticketPrice}</span> </h3>
                                        {/* <h4>Tax & VAT (0%) Include</h4> */}
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <div className="booking-cost">
                                    <p> By clicking to complete this booking I acknowledge that I have read and accept the Rules & Restrictions. </p>
                                    <Input type="submit" className="form-control btn" onClick={this.handlePay} value="Proceed" />
                                  </div>
                                </li>
                              </ul>
                              <ul className="list-box-sidebar">
                                <li>
                                  <div className="booking-help">
                                    <h3 className="box-title">
                                      Need Help Booking
                                    </h3>
                                    <p>
                                      Call our customer services team on the
                                      number below to speak to ..
                                    </p>
                                    <span className="phone-number">
                                      <i className="fas fa-phone-alt"></i>+267 75703276
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) =>{
  return {
    ...state
  }
}

const mapDispatchToProps = (dispatch) =>{
  return {
    updateBusBookingSeats: (data) =>{
      dispatch(updateBusBookingSeats(data))
    },
    updateBusBookingData: (data) =>{
      dispatch(updateBusBookingData(data))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
