import baseCountryConfig from "./baseConfig";

const SZ = {
  ...baseCountryConfig,
  countryName: "Eswatini",
  isoCode: "SZ",
  countryCode: "+268",
  iconName: "eswatini_flag",
  locale: "en-SZ",
  currencyCode: "SZL",
  currencySymbol: "SZL",
  isCountryEnabled: false,
};

export default SZ;
