import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { deletePost } from '../../actions/postActions';

const UserDashboard = (props)=>{
    
    useEffect(() =>{
        props.deletePost('1');
    }, []);

    return (
        <div>
            User Dashboard
        </div>
    )
}

const mapStateToProps = (state, ownProps) =>{
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        deletePost: (id) =>{dispatch(deletePost('2'))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
