const { REACT_APP_API, REACT_APP_WEB_URL } = process.env;
export const ENDPOINT = `${REACT_APP_API}`;
export let WEBURL = `${REACT_APP_WEB_URL}`;

export const API_URL = REACT_APP_API;
export const auth = "/app/uaa/v1";
export const user = "/app/v1/user"; 
export const city = "/app/v1/city";
export const terminal = "/app/v1/terminal";
export const routes = "/app/v1/routes";
export const busManagement = "/app/v1/bus";
export const faq = "/app/v1/miscellaneous/faq";
export const transaction = '/app/v1/transaction';

export const TOKEN = "TOKEN";
export const ID_TOKEN = "IDTOKEN";
export const REFRESH_TOKEN_CHECK_INTERVAL = 1 * 60 * 1000;
export const LOCAL_STORAGE_CUSTOMER_DATA_KEY = "LOCAL_STORAGE_CUSTOMER_DATA_KEY";
export const REQUIRED_STORE_REDUCERS = ["Auth", "resetPwd", "global", "profile", "cities"];
export const APP_CACHE_KEY = "sharearide";
export const GENERIC_ERROR_CODE = "GENERIC_ERROR";

export const days = [
    {
      value: "Sunday",
      label: "Sunday"
    },
    {
      value: "Monday",
      label: "Monday"
    },
    {
      value: "Tuesday",
      label: "Tuesday"
    },
    {
      value: "Wednesday",
      label: "Wednesday"
    },
    {
      value: "Thursday",
      label: "Thursday"
    },
    {
      value: "Friday",
      label: "Friday"
    },
    {
      value: "Saturday",
      label: "Saturday"
    },
];
export const alphabets = ['','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];