import baseCountryConfig from "./baseConfig";

const ZM = {
  ...baseCountryConfig,
  countryName: "Zimbabwe",
  isoCode: "ZW",
  countryCode: "+263",
  iconName: "zimbabwe_flag",
  locale: "en-ZW",
  currencyCode: "ZWL",
  currencySymbol: "Z$",
};

export default ZM;
