import baseCountryConfig from "./baseConfig";

const LS = {
  ...baseCountryConfig,
  countryName: "Lesotho",
  isoCode: "LS",
  countryCode: "+266",
  iconName: "lesotho_flag",
  locale: "en-LS",
  currencyCode: "LSL",
  currencySymbol: "M",
  isCountryEnabled: false,
};

export default LS;
