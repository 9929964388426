import axios from "../config/axiosConfig";
import { call } from "redux-saga/effects";
import { onRequestError, onCompleteRequest, formatRequest, } from "./common";
import { events } from "../actions";
import { GENERIC_ERROR_CODE, } from "../constants";

function getCities() {
  return axios(
    formatRequest(
      `/api/app/v2/city/all`,
      "get",
      null,
      false,
      false
    )
  );
}

export function* fetchCities() {
  try {
    const { data: apiResponse = {} } = yield call(
      getCities,
    );
    
    let { success, data = [], errors = [], message } = apiResponse;
    if (success) {
      data = data.map(city=> {
        return {
          value: city?.uuid,
          label: city?.name + ' (' + city?.name + ')',
        }
      });
      yield onCompleteRequest(
        events.cities.RECEIVED,
        data
      );
    } else {
      const errorObject = errors.length > 0 ? errors[0] : {};
      const errorCode = errorObject.errorCode || GENERIC_ERROR_CODE;
      const errorMsgFromBE = errorObject.errorMsg || message || "";
      yield onRequestError(
        events.cities.FAILED,
        errorCode,
        errorMsgFromBE
      );
    }
  } catch (e) {
    yield onRequestError(
      events.cities.FAILED,
      GENERIC_ERROR_CODE,
      e.message
    );
  }
}