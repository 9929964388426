import baseCountryConfig from "./baseConfig";

const MW = {
  ...baseCountryConfig,
  countryName: "Malawi",
  isoCode: "MW",
  countryCode: "+265",
  iconName: "malawi_flag",
  locale: "en-MW",
  currencyCode: "MWK",
  currencySymbol: "MK",
  phoneRegExp: /^[0-9]{12}$/,
  phoneNoLength: 12,
  isCountryEnabled: false,
}

export default MW;
