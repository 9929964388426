import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Link } from "react-router-dom";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1200 },
    items: 4,
    partialVisibilityGutter: 40
  },
  desktop: {
    breakpoint: { max: 1200, min: 992 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 992, min: 576 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
};

const CustomCarousel = ({terminals}) => {

  const slides = (terminals || []).map(function(terminal, key) {
    return(
      <div key={key} className="box-preview box-area-destination">
        <div className="box-img img-bg">
          <a href="#"> {terminal.image &&(<img src={ terminal?.image?.length === undefined || terminal?.image?.length < 1 ? require("../assets/images/files/defaultCity.jpg") : new Buffer.from(terminal?.image).toString("ascii")} alt=""/>)}</a>
          <div className="overlay"> <div className="overlay-inner"></div></div>
        </div>
        <div className="box-content">
          <i className="fas fa-map-marker-alt"></i>
          <div className="title">
            <h5><Link to="">{terminal?.cityName}</Link></h5>
          </div>
        </div>
      </div>
    );
  }, this);

  return (
    <Carousel responsive={responsive} centerMode={true} infinite={false}>
      {slides}
    </Carousel>
  );
};
export default CustomCarousel;
