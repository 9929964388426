import * as actionTypes from "./actionTypes";

const cancelErrorAction = () => ({ type: actionTypes.ERROR_CANCELED });

const completeLoadingAction = () => ({ type: actionTypes.LOADING_COMPLETED });

const requestLoadingAction = (apiType, actionName) => ({
  type: actionTypes.LOADING_RECEIVED,
  apiType,
  actionName,
});

const requestLogin = ( user, password, saveUserDataInCache = false, ) => ({
  type: actionTypes.REQUEST_LOGIN,
  user,
  password,
  saveUserDataInCache,
});

const requestSetPassword = (emailId, defaultPassword, password) => ({
  type: actionTypes.REQUEST_SET_PASSWORD,
  emailId,
  defaultPassword,
  password,
});

const requestChangePassword = (emailId) => ({
  type: actionTypes.REQUEST_CHANGE_PASSWORD,
  emailId
});

const destroySession = () => ({
    type: actionTypes.DESTROY_SESSION,
});

const updateAuthToken = () => ({
    type: actionTypes.REQUEST_REFRESH_JWT_TOKEN,
});

const fetchUserDetails = (userId) => ({
  type: actionTypes.REQUEST_USER_DETAILS,
  userId
});

const profileUpdate = (data) =>({
  type: 'PROFILE_UPDATE',
  data: data
});

const updateSearchFields = (data) =>({
  type: 'UPDATE_SEARCH_FIELDS',
  data: data
})

const updateBusRouteId = (data) =>({
  type: 'UPDATE_BUS_ROUTE_ID',
  data: data
})
const updateBusBookingSeats = (data) =>({
  type: 'UPDATE_BUS_BUS_BOOKING_SEATS',
  data: data
})
const updateBusBookingData = (data) =>({
  type: 'UPDATE_BUS_BOOKING_DATA',
  data: data
})
const recentBookingData = (data) =>({
  type: 'RECENT_BOOKING_DATA',
  data: data
})
const updateWalletBalance = (data) =>({
  type: 'UPDATE_WALLET_BALANCE',
  data: data
})
const fetchCities = () => ({
  type: actionTypes.REQUEST_CITY_DETAILS
});

const clearAllSearchRelatedHistory = () =>({
  type: 'PURGE'
})

export const actions = {
    auth: {
      requestLogin,
      updateAuthToken,
    },
    error: {
      cancelErrorAction,
    },
    loading: {
      requestLoadingAction,
      completeLoadingAction,
    },
    changePassword: {
      requestChangePassword,
      requestSetPassword,
    },
    session: {
      destroySession,
    },
    user:{
      fetchUserDetails,
      updateWalletBalance,
      profileUpdate
    },
    booking:{
      updateBusRouteId,
      updateBusBookingSeats,
      updateBusBookingData,
      recentBookingData,
      clearAllSearchRelatedHistory,
    },
    search:{
      updateSearchFields
    },
    cities:{
      fetchCities
    }
};

export const events = {
    auth: {
      REQUEST_REFRESH_JWT_TOKEN: actionTypes.REQUEST_REFRESH_JWT_TOKEN,
      RECEIVED_REFRESH_JWT_TOKEN: actionTypes.RECEIVED_REFRESH_JWT_TOKEN,
      FAILED_REFRESH_JWT_TOKEN: actionTypes.FAILED_REFRESH_JWT_TOKEN,
    },
    login: {
      REQUEST: actionTypes.REQUEST_LOGIN,
      RECEIVED: actionTypes.RECEIVED_LOGIN,
      FAILED: actionTypes.FAILED_LOGIN,
    },
    error: {
      RECEIVED: actionTypes.ERROR_RECEIVED,
      CANCELED: actionTypes.ERROR_CANCELED,
    },
    loading: {
      RECEIVED: actionTypes.LOADING_RECEIVED,
      COMPLETED: actionTypes.LOADING_COMPLETED,
    },
    changePassword: {
      REQUEST: actionTypes.REQUEST_CHANGE_PASSWORD,
      RECEIVED: actionTypes.RECEIVED_CHANGE_PASSWORD,
      FAILED: actionTypes.FAILED_CHANGE_PASSWORD,
    },
    session: {
      DESTROY_SESSION: actionTypes.DESTROY_SESSION,
    },
    user: {
      REQUEST: actionTypes.REQUEST_USER_DETAILS,
      RECEIVED: actionTypes.RECEIVED_USER_DETAILS,
      FAILED: actionTypes.FAILED_USER_DETAILS,
    },
    cities: {
      REQUEST: actionTypes.REQUEST_CITY_DETAILS,
      RECEIVED: actionTypes.RECEIVED_CITY_DETAILS,
      FAILED: actionTypes.FAILED_CITY_DETAILS,
    },
};