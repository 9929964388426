import React from "react";
import { Col, Container, Row, Button, Spinner, Table } from "reactstrap";
import axios from "axios";
import { ENDPOINT } from "../constants";
import { connect } from "react-redux";
import QRCode from "react-qr-code";
import domtoimage from 'dom-to-image';
import { default as JSPDF } from 'jspdf';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1200 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 1200, min: 992 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 992, min: 576 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1,
    },
};
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Ticket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tickets: [],
            loading: true,
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        const config = {
            dataType: "json",
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props?.Auth?.token,
            },
        };
        await axios.get(`${ENDPOINT}/app/v2/ticket-details/${this.props?.Auth?.user?.userId}/user-details`, config).then(res => {
            const { data, success } = res?.data;
            if (success) {
                this.setState({
                    tickets: data
                });
            }
        });

        this.setState({
            loading: false,
        });
    }

    renderTicket(ticketDetails) {
        const qrCodeData = {
            usernames: `${ticketDetails?.ticket?.fname} ${ticketDetails?.ticket?.lname}`,
            reservationNumber: ticketDetails?.ticket?.reservation_number,
            date: ticketDetails?.ticket?.booking_Date,
            departure: ticketDetails?.ticket?.departure,
            destination: ticketDetails?.ticket?.arrival,
            departureTime: ticketDetails?.ticket?.departureTime,
            busOperator: ticketDetails?.ticket?.partner_id,
            busRegistrationNumber: ticketDetails?.ticket?.reg_no,
            seats: ticketDetails?.ticket?.seats,
            ticketPrice: ticketDetails?.ticket?.fare,
            totalPrice: ticketDetails?.ticket?.ticket_Price,
            passengers: ticketDetails?.passengers
        }

        return <div key={ticketDetails?.ticket?.uuid}>
            <div style={{ width: '300px', margin: '0 auto' }}>
                <img alt="" src={require('../assets/images/files/ticket-top.png')} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="ticket-outer" id={"ticket" + ticketDetails?.ticket?.uuid} style={{ width: '300px' }}>
                    <div className="ticket-inner">
                        <QRCode value={JSON.stringify(qrCodeData)} size={100} bgColor={"#ff8476"} fgColor={"#ffffff"} />
                        <div style={{ float: "right", fontSize: "11px" }}>
                            Date:{ticketDetails?.ticket?.booking_date || ""}
                        </div>
                        <div className="ticket-heading" style={{ marginLeft: "80px", marginTop: "-80px" }}>ticket # {ticketDetails?.ticket?.reservation_number || ""}</div>
                        <div className="ticket-description-1" style={{ marginLeft: "80px" }}>
                            <div className="ticket-description-1-heading" style={{ display: 'inline-block' }}>{ticketDetails?.ticket?.name || ""}</div>
                        </div>
                        <div style={{ marginTop: "20px", borderBottom: "3px dashed #E3E7EE", paddingBottom: "5px" }}>
                            <div className="ticket-description-2-right" style={{ float: "right" }}>
                                <div className="bus-service">
                                    {
                                        this.state.ticketDetails?.image ? <img width={80} src={new Buffer.from(this.state.ticketDetails?.image).toString("ascii")} alt="" /> :
                                            <img width={60} src={require("../assets/images/files/bus.png")} alt="" />
                                    }
                                </div>
                            </div>
                            <div>
                                Operator: {this.state.ticketDetails?.bus_owner || ""} <br />
                                Reg #: <b>{this.state.ticketDetails?.reg_no || ""}</b>
                            </div>
                            <div style={{ width: "80px" }}></div>
                        </div>
                        <div className="ticket-description-2" style={{ marginTop: "15px" }}>
                            <div className="ticket-description-2-left">
                                <div className="bus-service">Boarding</div>
                                <div className="station"><b>  {ticketDetails?.ticket?.departureTime || ""}</b></div>
                                <div className="station time">({ticketDetails?.ticket?.departure || ""})</div>
                            </div>
                            <div className="ticket-description-2-right">
                                <div className="bus-service">Drop</div>
                                <div className="station"><b>  {ticketDetails?.ticket?.arrivalTime || ""}</b></div>
                                <div className="station time">({ticketDetails?.ticket?.arrival || ""})</div>
                            </div>
                        </div>
                        <div style={{ marginTop: "20px", borderBottom: "3px dashed #E3E7EE", paddingBottom: "5px" }}>
                            <Table bordered responsive size="sm" striped>
                                <thead>
                                    <tr>
                                        <th>Passenger</th>
                                        <th>Seat #</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(ticketDetails?.passengers || []).map(passenger => {
                                        return <tr>
                                            <td scope="row"> {passenger?.names || ""} </td>
                                            <td> {passenger?.seat_number || ""} </td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ width: '300px', margin: '0 auto' }}>
                <img alt="" src={require('../assets/images/files/ticket-bottom.png')} />
            </div>
            <div style={{ width: '300px', margin: '0 auto' }}>
                <Button id={'ticket' + ticketDetails?.ticket?.uuid + '-btn'} style={{ marginTop: '20px', paddingTop: '10px', paddingBottom: '10px', border: 'none' }} onClick={async () => {
                    var el = document.getElementById('ticket' + ticketDetails?.ticket?.uuid);
                    var img_src;
                    await domtoimage.toPng(el).then(function (dataUrl) {
                        var img = new Image();
                        img.src = dataUrl;
                        img_src = dataUrl;
                    });
                    const doc = new JSPDF();
                    doc.addImage(img_src, "PNG", 6, 5);
                    doc.save('ticket.pdf');
                }}>print</Button>
            </div>

        </div>
    }

    render() {

        return (
            <>
                {this.state.processing ?
                    <div style={{ opacity: 0.8, backgroundColor: '#ccc', position: 'fixed', width: '100%', height: '100%', top: '0px', left: '0px', zIndex: 1000 }}>
                        <div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
                            <Spinner animation="grow" variant='success' />
                        </div>
                    </div> :

                    <section className="page-single bg-grey">
                        <div id="content-wrap" style={{ marginTop: "-50px" }}>
                            <div className="section-flat">
                                <div className="section-content">
                                    {!this.state.loading &&
                                        <Carousel responsive={responsive} >
                                            {this.state.tickets.map(ticket => {
                                                return this.renderTicket(ticket)
                                            })}
                                        </Carousel>
                                    }
                                    <Container>
                                        <Row>
                                            <Col lg={12} md={12} >
                                                {this.state.loading && <RingLoader color="#02928b" loading={this.state.loading} css={override} size={150} />}
                                                {(!this.state.loading && this.state.tickets?.length === 0) &&
                                                    <div className="block-content-2 style-3">
                                                        <div>
                                                            <img alt="" style={{ width: '78px' }} src={require('../assets/images/files/bus-stop.svg')} />
                                                            <p style={{ fontSize: '30px', color: '#212a42', fontWeight: '600' }}>No Bookings Found</p>
                                                        </div>
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}

export default connect(mapStateToProps, null)(Ticket);