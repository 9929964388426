import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Footer from "../components/footers/Footer";
import Header from "../components/headers/Header";
import CustomNavbar from "../components/navbars/CustomNavbar";
import { ENDPOINT } from "../constants";
import alertify from "alertifyjs";
import axios from "axios";
import { persister } from "../redux/store";

class SingleBlog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      post_id: this.props.match.params.id,
      post: null,
      posts: [],
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    
    const config = {
      dataType: "json",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + this.props.Auth?.token,
      },
    };

    await axios
      .get(ENDPOINT + "/get-post/" + this.state.post_id, config)
      .then((response) => {
        if (response.data.code === 200) {
          this.setState({ post: response.data.post });
        }
        if (response.data.code === 404) {
          alertify.error(response.data.message);
          this.props.history.push("/blog");
        }
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          persister.purge();
        }
      });

    await axios
      .get(ENDPOINT + "/get-posts", config)
      .then((response) => {
        if (response.data.code === 200) {
          this.setState({ posts: response.data.posts });
        }
        if (response.data.code === 404) {
          alertify.error(response.data.message);
          return null;
        }
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          persister.purge();
        }
      });
  }
  render() {
	var listNews = this.state.posts.map(function(post, key){
		var d = new Date(post.created_at);
		var month = d.getMonth();
		var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'Decemeber'];
		month = months[month];
		var date = d.getDate();
		var year = d.getFullYear();
		
		return(
			<li key={key}>
				<div className="item-img">
					<Link to={"/post/"+post.id} className="img-bg">
					<div className="bg-element"></div>
						<img
						src="images/files/box-news-event/img-1.jpg"
						alt=""
						/>
					</Link>
				</div>
				<div className="item-content">
					<h6 className="title">
						<Link to={"/post/"+post.id}>
							{post.title}
						</Link>
					</h6>
					<span className="date">{month+" "+date+", "+year}</span>
				</div>
			</li>
		);
	}, this);

	var listItems = this.state.posts.map(function(post, key) {
		var d = new Date(post.created_at);
		var month = d.getMonth();
		var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'Decemeber'];
		month = months[month];
		var date = d.getDate();
		var year = d.getFullYear();

		return (
			<Col md={4} key={key}>         
				<div className="box-preview box-news-event">
					<div className="box-img img-bg">
						<div className="overlay">
							<div className="overlay-inner">
								<a className="lightbox-img" href="images/files/box-news-event/img-1.jpg"><i className="fas fa-search"></i></a>
							</div>
						</div>
					</div>
					<div className="box-content">
						<h4><a href="blog-single.html">{post.title}</a></h4>
						<p>
							{post.description}
						</p>
						<div className="meta-event">
							<span className="date">{month+" "+date+", "+year}</span>
							<Link to={"/post/"+post.id} className="btn-rm">Read More</Link>
						</div>
					</div>
				</div>
			</Col>
		);
	  }, this);



    return (
      <>
        <Header />
        <CustomNavbar />

        <Container>
          <Row>
            <Col md={12}>
            </Col>

            <Col md={12}>
              <div className="page-single-content">
                <Row>
                  <Col md={12} lg={8}>
                    {this.state.post ? (
                      <div className="content-main">
                        <div className="blog-post">
                          <ul className="list-categories">
                            <li>
                              <a href="javascript:;">
                                {this.state.post.category_title}
                              </a>
                            </li>
                          </ul>
                          <h2 className="title">{this.state.post.title}</h2>
                          <div className="post-img img-bg">
                            <div className="bg-element"></div>
                            <img
                              src="images/files/blog-posts/img-5.jpg"
                              alt=""
                            />
                          </div>
                          <div className="content">
                            <p>{this.state.post.body}</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col md={12} lg={4}>
                    <div className="sidebar style-2">
                      <div className="box-widget">
                        <div className="box-banner with-space">
                          <a href="javascript:;">
                            <img
                              src="images/files/box-banner/img-3.jpg"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                      <div className="box-widget">
                        <h5 className="box-title title-featured">
                          Latest News
                        </h5>
                        <div className="box-content">
                          <ul className="list-latest-posts">
							{listNews}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-5 mb-5">
					{listItems}
				</Row>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}

export default SingleBlog;
