
const getAuthInitialState = () => {
    return {
        profile_pic: '',
        from_cities: [{ value: 'Gaborone', label: 'Gaborone' },],
        to_cities: [{ value: 'Francistown', label: 'Francistown' },],
        search_from: 'Gaborone',
        search_to: 'Francistown',
        search_date: new Date(),
        bus_route_id: null,
        fare: null,
        base_fare: null,
        seats: null,
        booking_first_name: '',
        booking_last_name: '',
        booking_phone: '',
        booking_date: new Date(),
        recent_booking_data: null,
        balance: null,
        sub_route_id: null,
        bus_sub_route_id: null,
        bus_image: null,
        recent_url: null,
        booking_passengers: [],
        bus_id: null,
        arrivalSequenceNumber: null,
        departureSequenceNumber: null,
        selectedBusDetails: {}
    };
};

export default (state = getAuthInitialState(), response) => {
    switch (response.type) {
        case 'PROFILE_UPDATE':
            return {
                ...state,
                user: response.data.user,
                profile_pic: response.data.profile_pic,
            };
        case 'UPDATE_SEARCH_FIELDS':
            return{
                ...state,
                from_cities: response.data.from || {},
                to_cities: response.data.to || {},
                search_from: response.data.from || {},
                search_to: response.data.to || {},
                search_date: response.data.date
            };
        case 'UPDATE_BUS_ROUTE_ID':
            return{
                ...state,
                selectedBusDetails: response.data
            };
        case 'UPDATE_BUS_BUS_BOOKING_SEATS':
            return{
                ...state,
                seats: response.data.seats,
                fare: response.data.fare,
                base_fare: response.data.base_fare
            };
        case 'UPDATE_BUS_BOOKING_DATA':
            return{
                ...state,
                seats: response.data.booking_seats,
                booking_passengers: response.data.booking_passengers,
            };
        case 'RECENT_BOOKING_DATA':
            return{
                ...state,
                recent_booking_data: response.data
            };
        case 'UPDATE_WALLET_BALANCE':
            return{
                ...state,
                balance: 0
            };
        case 'REDIRECT_TO_RECENT_URL':
            return{
                ...state,
                recent_url: response.data.url
            };
        case "PURGE":
            return getAuthInitialState();
        default:
            return state;
    }
};
