import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { ENDPOINT } from "../constants";

import { Container, Row, Button, Spinner } from "reactstrap";
import QRCode from "react-qr-code";
import domtoimage from 'dom-to-image';
import { default as JSPDF } from 'jspdf';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1200 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1200, min: 992 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 992, min: 576 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
};

function Search ({...props}){
  const [loading, setLoading] = useState(true);
  const [bookingInformation, setBookingInformation] = useState({});
  const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  useEffect(() => {
    const fetchData = async () => {
      try{
        const config = {
          dataType: "json",
          headers: {
            'content-type': 'application/json',
          },
        };
        await axios.get(`${ENDPOINT}/app/v2/ticket-details/${props.match.params.bookingId}`, config).then(res=>{
          const { data, success } = res?.data;
          if(success){
            const qrData = {
              usernames: `${data?.fname} ${data?.lname}` ,
              reservationNumber: data?.reservation_number,
              date: data?.booking_Date,
              departure: data?.departure, 
              destination: data?.arrival, 
              departureTime: data?.departureTime, 
              busOperator: data?.partner_id, 
              busRegistrationNumber: data?.reg_no, 
              seats: data?.seats, 
              ticketPrice: data?.fare, 
              totalPrice: data?.ticket_Price
            }
            setBookingInformation({
              ticketDetails: data[0],
              qrCodeData: qrData
            });
          }
        })
      }catch(error) {
        console.log(error.message);
      }finally{
        setLoading(false)
      }
    } 
    fetchData();
  }, []);

  const render = () =>{
    return (
      <>
                        <section className="page-single bg-grey">
                    <div id="content-wrap">
                        <div className="section-flat">
                            <div className="section-content">
                                {!loading? 
                                    <Carousel responsive={responsive} >
                                        <div key={bookingInformation?.ticketDetails?.uuid}>
                                            <div style={{width: '300px', margin: '0 auto'}}>
                                                <img alt="" src={require('../assets/images/files/ticket-top.png')} />
                                            </div>
                                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                                <div className="ticket-outer" id={"ticket" + bookingInformation?.ticketDetails?.uuid} style={{width: '300px'}}>
                                                    <div className="ticket-inner">
                                                        <div className="ticket-heading">ticket # { bookingInformation?.ticketDetails?.reservation_number }</div>
                                                        <div className="ticket-description-1">
                                                            <div className="ticket-description-1-heading" style={{display: 'inline-block'}}>{bookingInformation?.ticketDetails?.name}</div>
                                                            <div className="ticket-description-1-date">{ month[new Date(bookingInformation?.ticketDetails?.booking_date).getMonth()] + ' ' + new Date(bookingInformation?.ticketDetails?.booking_date).getDate() }, { bookingInformation?.ticketDetails?.departure }</div>
                                                        </div>
                                                        <div className="ticket-description-2 mt-5">
                                                            <div className="ticket-description-2-left">
                                                                <div className="bus-service">Bus Service</div>
                                                                <div className="station"><b> Sharearide </b></div>
                                                            </div>
                                                            <div className="ticket-description-2-right">
                                                                <div className="bus-service">Booking  Seats</div>
                                                                <div className="station station-2">{bookingInformation?.ticketDetails?.seats?.replace(/'/g, '').split(',')}</div>
                                                            </div>                                    
                                                        </div>
                                                        <div className="ticket-description-2 mt-5 mb-5">
                                                            <div className="ticket-description-2-left">
                                                                <div className="bus-service">Boarding</div>
                                                                <div className="station"><b>  {bookingInformation?.ticketDetails?.departureTime}</b></div>
                                                                <div className="station time">({bookingInformation?.ticketDetails?.departure})</div>
                                                            </div>
                                                            <div className="ticket-description-2-right">
                                                                <div className="bus-service">Drop</div>
                                                                <div className="station"><b>  {bookingInformation?.ticketDetails?.arrivalTime}</b></div>
                                                                <div className="station time">({bookingInformation?.ticketDetails?.arrival})</div>
                                                            </div>                                    
                                                        </div>
                                                        <div className="ticket-description-2 mt-5 mb-3">
                                                            <QRCode value={JSON.stringify(bookingInformation?.qrCodeData)} />
                                                        </div>                                
                                                    </div>                                
                                                </div>
                                            </div>
                                            <div style={{width: '300px', margin: '0 auto'}}>
                                                <img alt="" src={require('../assets/images/files/ticket-bottom.png')} />
                                            </div>
                                            <div style={{width: '300px', margin: '0 auto'}}>
                                                <Button id={'ticket' + bookingInformation?.ticketDetails?.uuid +'-btn'} style={{marginTop: '20px', paddingTop: '10px', paddingBottom: '10px', border: 'none'}} onClick={async ()=>{
                                                    var el = document.getElementById('ticket' + bookingInformation?.ticketDetails?.uuid);
                                                    var img_src;
                                                    await domtoimage.toPng(el).then( function (dataUrl) {
                                                        var img = new Image();
                                                        img.src = dataUrl;
                                                        img_src = dataUrl;
                                                    });
                                                    const doc = new JSPDF();
                                                    doc.addImage(img_src, "PNG", 5, 5);
                                                    doc.save('ticket.pdf');
                                                }}>print</Button>
                                            </div>
                                        </div>
                                    </Carousel>
                                :
                                <div style={{opacity: 0.8, backgroundColor:'#ccc', position: 'fixed', width:'100%', height:'100%', top:'0px', left:'0px', zIndex:1000}}>
                                    <div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
                                        <Spinner animation="grow" variant='success' />
                                    </div>
                                </div>}
                                <Container>
                                    <Row/>
                                </Container>
                            </div>
                        </div>
                    </div>
                </section>
      </>
    );
  }

  return (render())
}

const mapStateToProps = (state) => {
  return state
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
