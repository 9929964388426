import baseCountryConfig from "./baseConfig";
const NG = {
  ...baseCountryConfig,
  countryName: "Mauritius",
  isoCode: "MU",
  countryCode: "+230",
  iconName: "mauritius_flag",
  locale: "en-MU",
  currencyCode: "MUR",
  currencySymbol: "Rs",
  isCountryEnabled: false,
};

export default NG;
