import axios from "../config/axiosConfig";
import { onRequestError, onCompleteRequest, formatRequest, callRestricatedAPI, } from "./common";
import { events } from "../actions";
import { GENERIC_ERROR_CODE, } from "../constants";

export function createUser(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${'v1'}/user/create/${userId}`,
      "post",
      data,
      true,
      true,
      1000 * 60 * 5 // 5 minutes as per 7323
    )
  );
}
export function getUser(userId) {
  return axios(
    formatRequest(
      `/api/app/v2/user/${userId}`,
      "get",
      null,
      true,
      true
    )
  );
}
export function updateUser(data, userId) {
  return axios(
    formatRequest(
      `/api/app/${'v1'}/user/${userId}/edit`,
      "put",
      data,
      true,
      true
    )
  );
}
export function removeUser(busType, userId) {
  return axios(
    formatRequest(
      `/api/app/${'v1'}/user/${userId}/${busType}`,
      "delete",
      undefined,
      true,
      true
    )
  );
}
export function changeAccountStatus(userId, status, accountId) {
  return axios(
    formatRequest(
      `/api/app/${'v1'}/user/${userId}/change-status/${status}/${accountId}`,
      "put",
      null,
      true,
      true
    )
  );
}

export function* fetchUserDetails(action) {
  try {
    const { data: apiResponse = {} } = yield callRestricatedAPI(
      getUser, 
      action.userId,
    );
    
    let { success, data = {}, errors = [], message } = apiResponse;
    data = {
      ...data,
      authDetails:{
        email: data.authDetails.email,
        username: data.authDetails.username
      }
    }

    if (success) {
      yield onCompleteRequest(
        events.user.RECEIVED,
        data
      );
    } else {
      const errorObject = errors.length > 0 ? errors[0] : {};
      const errorCode = errorObject.errorCode || GENERIC_ERROR_CODE;
      const errorMsgFromBE = errorObject.errorMsg || message || "";
      yield onRequestError(
        events.user.FAILED,
        errorCode,
        errorMsgFromBE
      );
    }
  } catch (e) {
    yield onRequestError(
      events.user.FAILED,
      GENERIC_ERROR_CODE,
      e.message
    );
  }
}