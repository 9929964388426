const privacyList = [
  {
    heading: "A.	INTRODUCTION\n",
    text:
      "sharearide recognizes the importance of privacy of its users and also of maintaining confidentiality of the information provided by its users as a responsible data controller and data processor.\n" +
      "This Privacy Policy provides for the practices for handling and securing user's Personal Information (defined hereunder) by sharearide and its subsidiaries and affiliates.\n" +
      "This Privacy Policy is applicable to any person (‘User’) who purchases, intends to purchase, or inquire about any product(s) or service(s) made available by sharearide through any of sharearide’s customer interface channels including its website, mobile site, mobile app & offline channels including call centers and offices (collectively referred herein as <b>'Sales Channels'</b>)\n" +
      "We encourage you to carefully read this Privacy Policy as, by providing us with your personal data, when accessing to our website, you acknowledge and accept the outlined conditions and the processing of the data for lawful and legitimate purposes.\n" +
      "Share A Ride expressly reserves the right to amend this Privacy Policy at any time and all amendments will be duly publicized by the same means.",
  },
  {
    heading: "Definition of personal data\n",
    text:
      "Personal data means any information or registration, regardless of the nature, support, or format, namely an audio, image, writing, initials, or other characteristic, relating to an identified or identifiable natural person.\n" +
      "An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to one or more specific personal data, considered separately or collectively, namely to the physical, physiological, mental, economic, ethnic, cultural, geographic, or social identity of that natural person.",
  },
  {
    heading: "The entity responsible for the personal data processing\n",
    text:
      "The entity responsible for the personal data processing is Share A Ride and, within the scope of the agreement with the data subject, we establish, in accordance with lawful and legitimate grounds, the data being collected and processed as well as the purposes of this collection and processing.\n" +
      "\n",
  },
  {
    heading: "Types of personal data being collected and processed\n",
    text:
      "Within the scope of its activity,Share A Ride collects and processes, namely:\n" +
      "• Personal data in order to supply products and services to its customers, such as name, tax number, address, telephone number, and email address, among others strictly necessary, proportional and legitimate.\n" +
      "• Personal data necessary for compliance with legal obligations, towards public or private entities, such as name, ID number, tax number, household composition, social security number, address(es) and telephone number(s), email address, health data, among others strictly necessary, proportional and legitimate.\n" +
      "• All the personal data needed for exercising the rights of Share A Ride, in accordance with its activity and legitimate interest, namely concerning accounting, tax and administrative management, litigation, judicial evidence, fraud detection, revenue protection and audit, systems and network management, control of information security as well as health and site safety.\n" +
      "\n" +
      "Without prejudice of the compliance with the legal rules or legitimate guidelines set forth by a competent authority concerning the data retention and transmission, Share A Ride solely processes the personal data required for its activity, strictly to the extent of the contract or other similar document celebrated with the subject of these data or through the prior, legitimate, lawful, and informed consent of the subject.",
  },
  {
    heading: "When and how we collect personal data\n",
    text:
      "Share A Ride collects the personal data through the website when you request information about products, register your data in the Knowledge Base, request technical and service assistance and sign up to receive our communications, we collect your personal data (for example, your first and last names, e-mail address) for purposes strictly necessary to provide you with our online Customer Service relevant to this website and to the assistance, with full privacy in respect of other users;\n" +
      "The collection of some personal data is mandatory and necessary to start and develop, normally and legally, a contractual relationship or collaboration; the lack or insufficiency of these data determine that this relationship with us will not happen and in this case Share A Ride will inform the subject of this necessary and mandatory nature.\n" +
      "Except for the data of this nature and those established in any public list or those that may be used in the legitimate interest of Share A Ride, your data will solely be collected and processed for purposes you previously consented to and this consent must be freely given and informed, specifically and unambiguously, by written or oral statement or through the validation of an option, namely for the subscription of newsletters or marketing communications; in those situations, further rules of this privacy policy shall apply.\n" +
      "If you no longer wish to receive these communications, you may unsubscribe them at any time.\n" +
      "The collected data will be processed in document, in compliance with the legislation concerning the protection of personal data, and they will be stored and retained in specific databases, created and managed for this sole purpose and with restricted and exclusive access of Share A Ride staff, as they have to process them within the scope of our activity. Under any circumstances the collected data may be used for purposes other than those that justified the subject consent, which is mandatory, or for lawful and legitimate purposes for the collection.",
  },
  {
    heading: "Purposes of the collection and processing of personal data\n",
    text:
      "Overall, the collected personal data aim at the contract management with customers, hired supplies, supplies adequacy to the customer’s needs and interests, sending of suggestions, information and marketing, information about campaigns, promotions, advertisement and news about the products, market and satisfaction surveys, complaints management, accounting, tax and administrative management, litigation, judicial evidence, fraud detection, revenue protection and audit, systems and network management, control of information security as well as health and site safety, compliance with the legal obligations and further purposes established by law for the legitimate interest of Share A Ride.\n" +
      "At the moment of the data collection, or upon your request, you will be informed in more detail about the processing of your data.",
  },
  {
    heading: "Retention of your personal data\n",
    text:
      "Share A Ride will comply with the statutory minimum period to retain data that may have been prescribed by a specific legal requirement.\n" +
      "Share A Ride will retain your personal data for the minimum period of time and as long as retention is no longer necessary for the purpose for which the information was collected and processed.",
  },
  {
    heading:
      "Access, rectification, objection, erasure, restriction, and portability of your personal data\n",
    text:
      "Share A Ride ensures that the data subject may have the right to request access, rectification, objection, erasure, restriction, and portability of his/her personal data.\n" +
      "The above-mentioned rights may be exercised by contacting directly Share A Ride, headquartered in Rua Dr. Manuel José Oliveira Machado, 37 – 4700-058 Braga, Portugal, or by email, at Share A Ridemg01@gmail.com.\n" +
      "You also have the right to lodge a complaint with the Portuguese competent data protection authority (Comissão Nacional de Proteção de Dados) – Av. D. Carlos I, 134 – 1.º 1200-651 Lisboa – Tel: 1 809 602 5000 – Fax: +351 253 202 089 – e-mail: Share A Ridemg01@gmail.com.",
  },
  {
    heading: "Personal data security measures we adopted\n",
    text:
      "As Share A Ride follows the best practices, we adopted a set of technical and organisation measures in order to prevent risks within the scope of security and protection of personal data; consequently, we approved and implemented a strict plan in accordance with the purposes, the law, and the interests of data subjects so as to ensure the protection of the data provided by the subjects who, in any way, interact with our company and to protect them from disclosure, loss, inappropriate use, change, processing, or unauthorised access as well as from any illegitimate processing.\n" +
      "Therefore, the personal data collection forms in digital format or paper, filled in at Share A Ride headquarters or on the website (with encrypted browser) are safely stored in our physical repositories and digital systems.\n" +
      "All the personal data you provided us with are stored in a Datacenter owned by Share A Ride, or by one of its subcontractors, in accordance with all the security and advanced logistical measures which are vital for the protection of your personal data.\n" +
      "Despite these security measures, we encourage those who surf the Internet to adopt further security measures in order to ensure that the security patches of your PC and browser are updated and that your firewall, antivirus and anti-spyware are on, as well as to make sure of the authenticity of the websites you visit and avoid those that seem unreliable.",
  },
  {
    heading:
      "Data disclosure to other entities, subcontractors or third parties\n",
    text:
      "Share A Ride may outsource the collection and processing of your personal data, for the same purposes set forth in this Policy; these entities shall contractually commit themselves to ensuring the reputation and the obligation to develop a set of technical and organisation measures in order to protect the personal data and to guarantee the subjects’ rights. Pursuant to law, some personal data may be disclosed do public authorities like Tax and Customs Authority (Autoridade Tributária), courts, and security forces.\n" +
      "Consequently, any outsourced entity shall process our Customers’ personal data, in the name and on behalf of Share A Ride, and must strive to adopt a set of technical and organization measures in order to prevent risks and protect the personal data from unintentional or illegitimate destruction, unintentional loss, change, disclosure or unauthorized access as well as from any illegitimate processing.",
  },
  {
    heading: "Personal data transfer\n",
    text:
      "The activity of Share A Ride may require the transfer of your personal data outside of Portugal.\n" +
      "In that case, Share A Ride will thoroughly observe the applicable legal provisions in order to ensure the reliability and adequacy of the foreign country concerning personal data protection and the applicable requirements of these cross-border transfers.",
  },
  {
    heading: "Cookies\n",
    text:
      "“Cookies” are small text files stored on your computers through the browser; they normally save information concerning your preferences and do not include, consequently, the processing of your personal data by Share A Ride.\n" +
      "Where not appropriate, and in accordance with the legal rules, the user will always be asked to provide his/her consent.\n" +
      "Cookies that we use:\n" +
      "• Strictly necessary cookies\n" +
      "These cookies are essential features of our website because they allow us to provide the required services.",
  },
  {
    heading: "Performance cookies\n",
    text:
      "These cookies collect anonymous information about how you use the website and the information is combined in order to improve how the website works. For example, we use Google Analytics cookies to help us identify how you arrived to the website and how you use it and they also enhance the areas we need to improve, such as browsing, purchase experience, and marketing campaigns. The information stored by these cookies never discloses personal data.\n" +
      "\n",
  },
  {
    heading: "Functionality cookies\n",
    text:
      "They store the preferences you have applied on the website, so you do not have to confirm them on future visits, and they offer you a more appropriate experience according to your choices and further customised and pleasant visits. The information is also shared anonymously with the information of other users to improve the operation system of the website. For example, the item related to stored products uses a cookie in order to know the favourite products you tagged during the browsing.\n" +
      "\n",
  },
  {
    heading: "Targeting/advertising cookies\n",
    text:
      "These cookies target the ads and the advertising campaigns according to the interests and likes of each user as well as they limit the number of times you see an ad, in order to measure the advertising efficiency and the success of the website functionalities. This information is shared with third parties, like advertisers. To avoid stored cookies on your computer and/or mobile devices without your permission, you may modify your browser definitions. The help window on your browser provides you with all the details you need to manage the cookies definitions.\n" +
      "\n",
  },
  {
    heading: "PRIVACY POLICY UPDATES\n",
    text:
      "Our Privacy Policy may change under legal or operational requests, all changes will be online on this page, and when it is needed you will be notified about major changes, always having the right of choosing.\n" +
      "\n",
  },
];

export default privacyList;
