import { PURGE } from "redux-persist";
import { events } from "../actions";
import { LOCAL_STORAGE_CUSTOMER_DATA_KEY, } from "../constants";

const getAuthInitialState = () => {
  const INITIAL_DATA = {
    isAuthenticated: false,
    token: null,
    errorCode: null,
    params: null,
    user: null,
    lockStartTime: null,
    refreshToken: null,
    isEmailVerified: false,
    profile: {}
  };
  try {
    const cacheData =
      JSON.parse(
        window.localStorage.getItem(LOCAL_STORAGE_CUSTOMER_DATA_KEY) || "{}"
      ) || {};
    if (cacheData && cacheData.email) {
      INITIAL_DATA.user = cacheData;
      INITIAL_DATA.lockStartTime = cacheData.lockStartTime
        ? new Date(cacheData.lockStartTime)
        : null;
      INITIAL_DATA.errorCode = cacheData.errorCode;
    }
  } catch (e) {}
  return INITIAL_DATA;
};

export default (state = getAuthInitialState(), response) => {
  switch (response.type) {
    case events.login.RECEIVED:
    case events.auth.RECEIVED_REFRESH_JWT_TOKEN:
      return {
        ...state,
        isAuthenticated: !!response.payload.accessToken,
        token: response.payload.accessToken,
        refreshToken: response.payload.idToken,
        errorCode: null,
        errorMessage: "",
        params: null,
        idToken: response.payload.idToken,
        user: {
          userId: response.payload.userId,
          role: response.payload.role,
          firstName: response.payload.firstName,
          lastName: response.payload.lastName,
          email: response.payload.email,
          profilePicUrl: response.payload.profilePicUrl,
          phone: response.payload.phone,
        },
        lockStartTime: null,
        isEmailVerified: response.payload.isEmailVerified,
      };
    case events.auth.FAILED_REFRESH_JWT_TOKEN:
    case events.login.FAILED:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        refreshToken: null,
        errorCode: response.errorCode,
        params: response.params,
        user: null,
        lockStartTime: response.params ? response.params.lockStartTime : null,
        isEmailVerified: false,
        errorMessage: response.message
      };
    case events.login.CANCELED:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        user: null,
        params: null,
        lockStartTime: null,
        errorCode: null,
        refreshToken: null,
        isEmailVerified: false,
      };
    case events.error.CANCELED:
      return {
        ...state,
        lockStartTime: null,
        errorCode: null,
      };
    case PURGE:
      return getAuthInitialState();
    default:
      return state;
  }
};
