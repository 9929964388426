import baseCountryConfig from "./baseConfig";

const ZM = {
  ...baseCountryConfig,
  countryName: "Zambia",
  isoCode: "ZM",
  countryCode: "+260",
  iconName: "zambia_flag",
  locale: "en-ZM",
  currencyCode: "ZMW",
  currencySymbol: "ZK",
};

export default ZM;
