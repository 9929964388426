import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { connect } from "react-redux";
import { updateSearchFields } from "../actions/searchActions";
import alertify from "alertifyjs";
import Footer from "../components/footers/Footer";
import CustomNavbar from "../components/navbars/CustomNavbar";
import CustomCarousel from "./CustomCarousel";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { publicStatistics } from "../sagas/global";
import axios from "axios";
import { ENDPOINT } from "../constants";
import { actions } from "../actions";
import { createBrowserHistory } from 'history';

function LandingPage ({  
  updateSearchFields,
  fetchSupportedCities,
  cities
}){

  const [loading, setLoading] = useState(true);
  const [fromCities, setFromCities] = useState([]);
  const [toCity, setToCity] = useState([]);
  const [selectedFromCity, setSelectedFromCity] = useState(null);
  const [selectedToCity, setSelectedToCity] = useState(null);
  const [date, setDate] = useState(new Date());
  const [customers, setCustomers] = useState(null);
  const [buses, setBuses] = useState(null);
  const [ticketsSold, setTicketsSold] = useState(null);
  const [routes, setRoutes] = useState(null);
  const [terminals, setTerminals] = useState([]);
  const history = createBrowserHistory();

  const customStyles = {
    menu: (provided) =>({
      ...provided,
      zIndex: 999
    }),
    control: (provided) => ({
      ...provided,
      marginTop: "0%",
      height: "55px",
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
      borderRadius: "0px",
    }),
    placeholder: (provided) => ({
      ...provided,
      marginTop: "6px",
      marginLeft: "26px",
      fontSize: "16px",
    }),
    input: (provided) => ({
      ...provided,
      marginLeft: "34px",
      fontSize: "16px",
    }),
    singleValue: (provided) => ({
      ...provided,
      marginLeft: "34px",
      fontSize: "16px",
      marginTop: "6px",
    }),
    option: (provided) => ({
      ...provided,
    }),
  };

  const cStyles = {
    menu: (provided) =>({
      ...provided,
      zIndex: 999
    }),
    control: (provided) => ({
      ...provided,
      marginTop: "0%",
      height: "55px",
      borderRadius: "0px",
    }),
    placeholder: (provided) => ({
      ...provided,
      marginTop: "6px",
      marginLeft: "26px",
      fontSize: "16px",
    }),
    input: (provided) => ({
      ...provided,
      marginLeft: "34px",
      fontSize: "16px",
    }),
    singleValue: (provided) => ({
      ...provided,
      marginLeft: "34px",
      fontSize: "16px",
      marginTop: "6px",
    }),
    option: (provided) => ({
      ...provided,
    }),
  };

  const DropdownIndicator = () => {
    return null;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchSupportedCities();
  }, []);

  useEffect(() => {
    if(cities){
      setFromCities(cities)
      setToCity(cities);
    } 
  }, [cities]);

  useEffect(() => {
    fetchPublicData();
    fetchDestinationStatistics();
    setLoading(false);
  }, []);

  const fetchDestinationStatistics = async() =>{
    try{
      const config = {
        dataType: "json",
        headers: {
          'content-type': 'application/json',
        },
      };  
      axios.get(`${ENDPOINT}/app/v1/city/destinations/top`, config).then(response => {
        const { data = [], success } = response?.data;
        if (success) {
          var filteredResults = [];
          if(data.length > 0){
            data.filter(function(item){
              var i = filteredResults.findIndex(x => (x.cityId === item.cityId));
              if(i <= -1){
                filteredResults.push(item);
              }
              return null;
            });
            setTerminals(filteredResults);
          }        
        }
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  const fetchPublicData = async() =>{
    try {
      const apiResponse = await publicStatistics();
      const { data = [], success } = apiResponse?.data;
      if (success) {
        setCustomers(data.customers);
        setBuses(data.buses);
        setTicketsSold(data.ticketsSold);
        setRoutes(data.routes);
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  const handleFromCitySelectChange = (option) => {
    if(selectedToCity !== undefined && selectedToCity !== null){
      if(selectedToCity.value === option.value){
        alertify.error('Departure and Destination place can not be the same');
      } else{
        setSelectedFromCity(option);
      }
    } else{
      setSelectedFromCity(option);
    }
  };

  const handleToCitySelectChange = (option) => {
    if(selectedFromCity !== undefined && selectedFromCity !== null){
      if(selectedFromCity.value === option.value){
        alertify.error('Departure and Destination place can not be the same');
      } else{
        setSelectedToCity(option);
      }
    } else{
      setSelectedToCity(option);
    }
  };

  const handleDateChange = (e) => {
    setDate(e)
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    var from_el = document.getElementById("from-slct");
    var to_el = document.getElementById("to-slct");

    from_el.style.border = "0px";
    to_el.style.border = "0px";

    if (selectedFromCity === null) {
      alertify.notify('Departure place can not be blank', 'custom', 2, function(){});
      from_el.style.border = "1px solid #ff8476";
      return;
    } else {
      from_el.style.border = "0px";
    }

    if (selectedToCity === null) {
      alertify.notify('Destination place can not be blank', 'custom', 2, function(){});
      to_el.style.border = "1px solid #ff8476";
      return;
    } else {
      to_el.style.border = "0px";
    }

    if((selectedFromCity !== undefined && selectedFromCity !== null) && (selectedToCity !== undefined && selectedToCity !== null)){
      if(selectedFromCity.value === selectedToCity.value){
        alertify.notify('Departure and Destination can not be the same place', 'custom', 2, function(){});
      } else{
        const data = {
          from: selectedFromCity,
          to: selectedToCity,
          date: date,
        };        
        updateSearchFields(data);
        history.push({
          pathname: "/search"
        });
        window.location.reload();
      }
    }
  };

  return (
    <>
      <CustomNavbar />        
      <section id="content" className="home-content">
        <div id="content-wrap">
          <header className="main-header">
            <div className="container">                
              <div className="search-outer">
                <div className="search-inner">
                  <div className="search-text">
                    <div className="search-text-1">Welcome to sharearide</div>
                    <div className="search-text-2">Experience Convenient Transport Booking Today</div>
                  </div>                    
                  <form className="search-form">
                    <div className="seprator">
                      <img src={require("../assets/images/files/double-arrow.svg")} alt="" />
                    </div>
                    <div className="form-group">
                      <label style={{ zIndex: "1", paddingLeft: "34px" }} htmlFor="Departure" > Departure </label>                        
                      <Select
                        id="from-slct"
                        className="col-md-12 no-padding home-search-select"
                        defaultValue={selectedFromCity}
                        isLoading={loading}
                        onChange={handleFromCitySelectChange}
                        isSearchable={true}
                        styles={customStyles}
                        options={fromCities}
                        components={{
                          DropdownIndicator,
                          IndicatorSeparator: () => null,
                        }} />

                      <img className="srch-ico" src={require("../assets/images/files/city.png")} alt=""/>
                    </div>
                    <div className="form-group building-2-space">
                      <label style={{ zIndex: "1", paddingLeft: "34px" }} htmlFor="Destination" > Destination </label>
                      <img className="srch-ico" src={require("../assets/images/files/city.png")} alt=""/>
                      <Select
                        id="to-slct"
                        className="col-md-12 no-padding home-search-select"
                        defaultValue={selectedToCity}
                        isLoading={loading}
                        onChange={handleToCitySelectChange}
                        isSearchable={true}
                        styles={cStyles}
                        options={toCity}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }} />
                    </div>
                    <div className="form-group">
                      <label style={{ zIndex: "1", paddingLeft: "34px" }} htmlFor="date-input" > Date </label>
                      <img className="srch-ico calender-ico" src={require("../assets/images/files/calender.png")} alt=""/>
                      <DatePicker
                        selected={date}
                        onChange={handleDateChange}
                        minDate={new Date()}
                        className="riding-date" />
                    </div>
                    <div className="form-group search-btn-outer">
                      <button type="submit" className="btn btn-primary" onClick={handleSearch} > Search Bus </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </header>

          {/* === Section About 1 =========== */}
          <div id="section-about-1" className="section-flat">
            <div className="section-content">
              <Container>
                <Row>
                  <Col md={12}>
                    <div className="section-title text-center">
                      <span>
                        <em></em>
                      </span>
                      <h2 className="mt-4">About Sharearide</h2>
                      <p> Sharearide is the most efficient and effective way to get transportation around Botswana.</p>
                    </div>
                  </Col>
                  <div className="clearfix"></div>
                  <Col md={12}>
                    <Row>
                      <Col md={6} lg={3}>
                        <div className="box-info box-about-1">
                          <div className="box-content">
                            <h4><i className="far fa-heart"></i> About Us </h4>
                            <p style={{textAlign: 'justify'}}>
                              We provide an easy-to-use platform for commuters to book a ride, and for transport operators to
                              monitor and manage their fleet online.
                            </p>
                          </div>                            
                        </div>                          
                      </Col>
                      {/* .col-md-6 end */}
                      <Col md={6} lg={3}>
                        <div className="box-info box-about-1 mt-4 mt-lg-0">
                          <div className="box-content">
                            <h4><i className="far fa-bell"></i>Our Services </h4>
                            <p style={{textAlign: 'justify'}}>
                              We offer a seamless and safe public transportation booking and management experience for
                              commuters and transport operators in Botswana.
                            </p>
                          </div>                            
                        </div>                         
                      </Col>
                      <Col md={6} lg={3}>
                        <div className="box-info box-about-1 mt-4 mt-lg-0">
                          <div className="box-content">
                            <h4><i className="far fa-flag"></i>Our Partners</h4>
                            <p style={{textAlign: 'justify'}}> Our partner companies are reputable transport operators with years of experience in passenger travel. </p>
                          </div>
                        </div>
                      </Col>
                      <Col md={6} lg={3}>
                        <div className="box-info box-about-1 mt-4 mt-lg-0">
                          <div className="box-content">
                            <h4><i className="far fa-compass"></i>Our Routes</h4>
                            <p style={{textAlign: 'justify'}}>
                              As our partner network grows, so to the number of routes provided. With Sharearide, boarding
                              and dropping off at different destinations is made easy.
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </div> 
          <section className="book pt-3 ">
            <div className="section-title book-heading   text-center">
              <span>
                <em></em>
              </span>
              <h2 className="mt-4">Why book with us ?</h2>
              <p>Book your travel Sharearide and enjoy a seamless travel experience</p>
            </div>
            <div className="book-sections">
              <Container>
                <Row>
                  <Col lg={3}>
                    <div className="book-single-outer">
                      <div className="book-single-inner">
                        <div className="book-top pt-3 pb-3">
                          <div className="book-top-img">
                            <img
                              src={require("../assets/images/files/users.png")}
                              alt=""
                            />
                          </div>
                          <h4 className="text-center  mt-4">
                            {customers}
                        </h4>
                        </div>
                        <div className="book-bottom pt-2 pb-2 text-center">
                          Customers
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="book-single-outer">
                      <div className="book-single-inner">
                        <div className="book-top pt-3 pb-3">
                          <div className="book-top-img">
                            <img
                              src={require("../assets/images/files/bus.svg")}
                              alt=""
                            />
                          </div>
                          <h4 className="text-center  mt-4">
                            {buses}
                          </h4>
                        </div>
                        <div className="book-bottom pt-2 pb-2 text-center">
                          Buses
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="book-single-outer">
                      <div className="book-single-inner">
                        <div className="book-top pt-3 pb-3">
                          <div className="book-top-img">
                            <img
                              src={require("../assets/images/files/ticket_coral.svg")}
                              alt=""
                            />
                          </div>
                          <h4 className="text-center  mt-4">
                            {ticketsSold}
                          </h4>
                        </div>
                        <div className="book-bottom pt-2 pb-2 text-center">
                          bus tickets sold
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="book-single-outer">
                      <div className="book-single-inner">
                        <div className="book-top pt-3 pb-3">
                          <div className="book-top-img">
                            <img
                              src={require("../assets/images/files/route_coral.svg")}
                              alt=""
                            />
                          </div>
                          <h4 className="text-center  mt-4">
                            {routes}
                          </h4>
                        </div>
                        <div className="book-bottom pt-2 pb-2 text-center">
                          routes
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section className="works  pb-3">
            <div className="section-title book-heading   text-center">
              <span><em></em></span>
              <h2 className="mt-4">How it works</h2>
              <p>Book and travel in 3 simple steps</p>
            </div>
            <div className="work-sections">
              <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    <div className="work-single">
                      <div className="works-img text-center">
                        <img
                          src={require("../assets/images/files/undraw_booking.svg")}
                          alt=""
                        />
                      </div>
                      <h4 className="text-center  mt-4">Book</h4>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="work-single">
                      <div className="works-img text-center">
                        <img
                          src={require("../assets/images/files/undraw_Mobile_pay.svg")}
                          alt=""
                        />
                      </div>
                      <h4 className="text-center  mt-4">Pay</h4>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="work-single">
                      <div className="works-img text-center">
                        <img
                          src={require("../assets/images/files/undraw_confirme.svg")}
                          alt=""
                        />
                      </div>
                      <h4 className="text-center  mt-4">Confirm</h4>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="work-single">
                      <div className="works-img text-center">
                        <img
                          src={require("../assets/images/files/undraw_travelers.svg")}
                          alt=""
                        />
                      </div>
                      <h4 className="text-center  mt-4">Travel</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* === Section Top Destinations =========== */}
          <div id="section-top-destintations" className="section-flat">
            <div className="section-content ">
              <Container>
                <Row>
                  <Col md={12} className="pt-5">
                    <div className="section-title book-heading   text-center">
                      <span>
                        <em></em>
                      </span>
                      <h2 className="mt-4">Top Destinations</h2>
                      <p>Our most popular destinations in Botswana</p>
                    </div>
                  </Col>
                </Row>
              </Container>
              <div className="slider-top-destinations">
                <CustomCarousel terminals={terminals}/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="section-about-1" className="section-flat">
        <div className="section-content partner-outer">
          <Container>
            <Row>
              <Col md={12}>
                <div className="section-title book-heading   text-center">
                  <span>
                    <em></em>
                  </span>
                  <h2 className="mt-4">Our Partners</h2>
                  <p>Our trusted partners includes</p>
                </div>
              </Col>
              <div className="clearfix"></div>
              <Col md={12}>
                <Row>
                  <Col md={6} lg={4}>
                    <div className="box-info box-about-1">
                      <div className="box-content">
                        <div className="partner-img">
                          <img
                            className="mb-10 logo-footer"
                            src={require("../assets/images/files/CorrugatedMinds.png")}
                            alt="Corrugated Minds"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} lg={4}>
                    <div className="box-info box-about-1 mt-4 mt-lg-0">
                      <div className="box-content">
                        <div className="partner-img">
                          <img
                            className="mb-10 logo-footer"
                            src={require("../assets/images/files/bih.jpg")}
                            alt="BIH"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} lg={4}>
                    <div className="box-info box-about-1 mt-4 mt-lg-0">
                      <div className="box-content">
                        <div className="partner-img">
                          <img
                            className="mb-10 logo-footer"
                            src={require("../assets/images/files/mote.png")}
                            alt="MOTE"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
}

function mapStateToProps(state) {
	return {
    ...state,
    cities: state?.cities?.details
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateSearchFields: ( data ) => {
      dispatch(updateSearchFields(data));
    },
    fetchSupportedCities: () => {
      dispatch(actions.cities.fetchCities());
    },
  };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);