import { PURGE } from "redux-persist";
import { events } from "../actions";

const INITIAL_STATE = {
  errorCode: null,
  errorMessage: "",
  details: {},
};

export default (state = INITIAL_STATE, response) => {
  switch (response.type) {
    case events.user.RECEIVED:
      return {
        ...state,
        errorCode: "",
        errorMessage: "",
        details: response.payload
      };
    case events.user.FAILED:
      return {
        ...state,
        errorCode: response.errorCode,
        details: {},
        errorMessage: response.message
      };
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
