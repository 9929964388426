import baseCountryConfig from "./baseConfig";

const TZ = {
  ...baseCountryConfig,
  countryName: "Tanzania",
  isoCode: "TZ",
  countryCode: "+255",
  iconName: "tanzania_flag",
  languages: [
    ...baseCountryConfig.languages,
    { name: "Swahili", locale: "swa" },
  ],
  locale: "en-TZ",
  currencyCode: "TZA",
  currencySymbol: "TSh",
  phoneRegExp: /^[0-9]{9}$/,
  phoneNoLength: 9,
  isCountryEnabled: false,
};

export default TZ;
