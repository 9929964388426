import AO from "./AO";
import BW from "./BW";
import LS from "./LS";
import MG from "./MG";
import MU from "./MU";
import MW from "./MW";
import MZ from "./MZ";
import NA from "./NA";
import SZ from "./SZ";
import TZ from "./TZ";
import ZA from "./ZA";
import ZM from "./ZM";
import ZW from "./ZW";

export const activeCountriesConfig = {
  angola: AO,
  botswana: BW,
  lesotho: LS,
  madagascar: MG,
  mauritius: MU,
  malawi: MW,
  mozambique: MZ,
  namibia: NA,
  eswatini: SZ,
  tanzania: TZ,
  south_africa: ZA,
  zambia: ZM,
  zimbabwe: ZW,
};

export default activeCountriesConfig;
