import baseCountryConfig from "./baseConfig";

const ZA = {
  ...baseCountryConfig,
  countryName: "South Africa",
  isoCode: "ZA",
  countryCode: "+27",
  iconName: "south_africa_flag",
  locale: "en-ZA",
  currencyCode: "ZAR",
  currencySymbol: "R",
  phoneRegExp: /^[0-9]{9}$/,
  phoneNoLength: 9,
};

export default ZA;
