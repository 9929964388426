import baseCountryConfig from "./baseConfig";
const MG = {
  ...baseCountryConfig,
  countryName: "Madagascar",
  isoCode: "MG",
  countryCode: "+261",
  iconName: "madagascar_flag",
  locale: "en-MG",
  currencyCode: "MGA",
  currencySymbol: "Ar",
  phoneRegExp: /^[0-9]{7}$/,
  phoneNoLength: 7,
  isCountryEnabled: false,
};

export default MG;
