import React, { useState, useEffect } from "react";
import { Container, Input, Label, FormGroup, Spinner, Col } from "reactstrap";
import Footer from "../components/footers/Footer";
import Header from "../components/headers/Header";
import CustomNavbar from "../components/navbars/CustomNavbar";
import { connect } from 'react-redux';
import { signupSuccess } from '../actions/userActions';
import alertify from "alertifyjs";
import axios from "axios";
import { ENDPOINT } from "../constants";
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { PhoneNumberFormat, PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';
import { activeCountriesConfig } from "../utils/countryConfigs";
import { createBrowserHistory } from 'history';

const phoneUtil = PhoneNumberUtil.getInstance();
const countryList = Object.values(activeCountriesConfig);
function SignUp({
  isAuthenticated,
  signupSuccess
}) {
  const history = createBrowserHistory();
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [username, setUsername] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [processing, setProcessing] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [width, setWidth] = useState("300px");
  const [countryCode, setCountryCode] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countryList[1]);

  useEffect(() => {
    window.scrollTo(0, 0);
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    if (isAuthenticated) {
      history.push({
        pathname: "/user/profile",
        search: window.location.search,
      });
    }
  }, []);

  const handleChange = (e) => {
    if (e.target.id === 'registerFirstname') {
      setFname(e.target.value);
    }
    if (e.target.id === 'registerLastname') {
      setLname(e.target.value);
    }
    if (e.target.id === 'registerUsername') {
      setUsername(e.target.value);
    }
    if (e.target.id === 'registerEmail') {
      setEmail(e.target.value);
    }
    if (e.target.id === 'registerPassword') {
      setPassword(e.target.value);
    }
    if (e.target.id === 'registerPasswordConfirm') {
      setConfirmPassword(e.target.value);
    }
  }

  const handlePhoneChange = (_value, data, _event, formattedValue) => {
    const userSelectedCountry = countryList.find((c) => c.isoCode.toLowerCase() === (data?.countryCode || "").toLowerCase()) || {};
    const length = userSelectedCountry?.phoneNoLength;
    const mobileInput = formattedValue?.replace(userSelectedCountry?.countryCode, "").replace(/\s/g, "").trim();
    if (mobileInput.length === length) {
      const language = userSelectedCountry?.languages?.length > 0 ? userSelectedCountry?.languages[0]?.locale : "en";
      setSelectedCountry(userSelectedCountry);
      setContactNumber(formattedValue);
    }
  }

  const acceptTerms = async () => {
    setAcceptedTerms(!acceptedTerms);
  }

  const handleclick = async (e) => {
    e.preventDefault();
    var fname_el = document.getElementById('registerFirstname');
    var lname_el = document.getElementById('registerLastname');
    var username_el = document.getElementById('registerUsername');
    var email_el = document.getElementById('registerEmail');
    var password_el = document.getElementById('registerPassword');
    var password_confirm_el = document.getElementById('registerPasswordConfirm');

    if (fname === "") {
      alertify.notify('First name cannot be empty', 'custom', 2, function () { });
      fname_el.style.border = "1px solid #ff8476";
      return;
    } else {
      fname_el.style.border = "1px solid #ddd";
    }

    if (lname === "") {
      alertify.notify('Last name can not be empty', 'custom', 2, function () { });
      lname_el.style.border = "1px solid #ff8476";
      return;
    } else {
      lname_el.style.border = "1px solid #ddd";
    }

    if (username === "") {
      alertify.notify('Username cannot be empty', 'custom', 2, function () { });
      username_el.style.border = "1px solid #ff8476";
      return;
    } else {
      username_el.style.border = "1px solid #ddd";
    }

    if (contactNumber === "") {
      alertify.notify('Contact number cannot be empty', 'custom', 2, function () { });
      return;
    } else {
      if (!isPhoneNumberValid) {
        alertify.notify('Invalid phone number', 'custom', 2, function () { });
        return;
      }
    }

    if (email !== "") {
      if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
        alertify.notify('Invalid email', 'custom', 2, function () { });
        email_el.style.border = "1px solid #ff8476";
        return;
      } else {
        email_el.style.border = "1px solid #ddd";
      }
    }

    if (!/^[A-Za-z\d@$!%^*#?&]{6,20}$/.test(password)) {
      if (password === "") {
        alertify.notify('Password cannot be empty', 'custom', 2, function () { });
        password_el.style.border = "1px solid #ff8476";
        return;
      } else {
        alertify.notify('Password should only contain 6 to 20 characters long', 'custom', 2, function () { });
        password_el.style.border = "1px solid #ff8476";
        return;
      }
    } else {
      password_el.style.border = "1px solid #ddd";
    }

    if (password !== confirmPassword) {
      alertify.notify('Password do not match', 'custom', 2, function () { });
      password_confirm_el.style.border = "1px solid #ff8476";
      return;
    } else {
      password_confirm_el.style.border = "1px solid #ddd";
    }

    if (!acceptedTerms) {
      alertify.notify('Please accept the terms of use first', 'custom', 2, function () { });
      return;
    }
    const parsedNumber = phoneUtil.parse(contactNumber);
    const format = phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
    setProcessing(true);
    const data = {
      "data": {
        "user": {
          "id": null,
          "uuid": null,
          "fname": fname,
          "lname": lname,
          "phone": format.replace(/ +/g, ""),
          "image": null,
          "role": "user",
          "status": "INACTIVE"
        },
        "address": null,
        "login": {
          "username": username,
          "email": (email.length > 0 ? email : null),
          "password": password
        }
      }
    }

    await registerUser(data).then(async response => {
       setProcessing(false)
      if(response?.data?.success){        
        alertify.notify('Account created successfully, please check your primary contact for OTP and verification steps', 'customsuccess', 2, function(){});
        signupSuccess(data);
        history.push({
          pathname: "/verify",
          search: window.location.search,
        });
      } else{
        if(Array.isArray(response?.data?.errors)){
          alertify.notify(response?.data?.errors[0]?.errorMsg, 'custom', 2, function(){});
        }else{
          alertify.notify('Server encountered errors processing your request', 'custom', 2, function(){});
        }
        return null;        
      }
    });
  }

  const registerUser = async (data) => {
    return await axios.post(`${ENDPOINT}/app/v1/user/create/`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      dataType: "json"
    });
  }

  const updateWindowDimensions = () => {
    if (window.innerWidth < 375) {
      setWidth('300px');
      return;
    } else if (window.innerWidth < 444) {
      setWidth('317px' );
      return;
    } else if (window.innerWidth < 478) {
      setWidth('350px' );
      return;
    } else if (window.innerWidth < 540) {
      setWidth('400px' );
      return;
    } else if (window.innerWidth < 576) {
      setWidth('442px' );
      return;
    } else if (window.innerWidth < 670) {
      setWidth('460px' );
      return;
    } else if (window.innerWidth < 768) {
      setWidth('460px');
      return;
    } else {
      setWidth('600px' );
      return;
    }
  }

  const isButtonDisabled = () => {
    return (
      processing || !acceptedTerms ||
      (password !== confirmPassword) ||
      password === "" ||
      contactNumber === "" || username === "" ||
      lname === "" || fname === "" || !isPhoneNumberValid
    );
  }

  return (
    <>
      <Header />
      <CustomNavbar />
      <section className="popup-preview-2 popup-preview-login login-section pb-90 pt-90">
        <Container>
          <Row className="justify-content-md-center align-items-center">
            <div className="block-content">
              <div className="block-title">
                <h3>Join Sharearide for Free</h3>
                <h5>Access various bus operator services, a wide range of buses and journey to your place of interest</h5>
              </div>
              <div className="content">
                <div >
                  {processing ?
                    <div style={{ opacity: 0.8, backgroundColor: '#ccc', position: 'fixed', width: '100%', height: '100%', top: '0px', left: '0px', zIndex: 1000 }}>
                      <div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
                        <Spinner animation="grow" variant='success' />
                      </div>
                    </div> :
                    <form id="form-register" className="rounded">
                      <div className="form-content">
                        <Row className="align-items-left" >
                          <Col xs={12}>
                            <div className="form-group">
                              <label htmlFor="registerFirstname">First Name<span style={{ color: "red" }}>&nbsp;*</span></label>
                              <input type="text" name="fName" id="registerFirstname" className="form-control" placeholder="" value={fname} onChange={handleChange} />
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="form-group">
                              <label htmlFor="registerLastname">Last Name<span style={{ color: "red" }}>&nbsp;*</span></label>
                              <input type="text" name="lName" id="registerLastname" className="form-control" placeholder="" value={lname} onChange={handleChange} />
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="form-group">
                              <label htmlFor="registerUsername">Username<span style={{ color: "red" }}>&nbsp;*</span></label>
                              <input type="text" name="userName" id="registerUsername" className="form-control" placeholder="" value={username} onChange={handleChange} />
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="form-group">
                              <label htmlFor="registerContactNumber">Contact Number<span style={{ color: "red" }}>&nbsp;*</span></label>
                              <PhoneInput enableAreaCodes={true} name="cellphone" id="registerContactNumber"
                                inputStyle={{
                                  width: width,
                                  height: '45px'
                                }}
                                country={'bw'}
                                enableSearch={false}
                                countryCodeEditable={false}
                                onlyCountries={countryList.filter((c) => c.isCountryEnabled === true).map((c) => c.isoCode.toLowerCase())}
                                value={contactNumber} onChange={handlePhoneChange} style={{ width: "80%" }}
                                isValid={(value, country) => {
                                  try {
                                    const userSelectedCountry = countryList.find((c) => c.isoCode.toLowerCase() === (country?.iso2 || "").toLowerCase()) || {};
                                    const length = userSelectedCountry?.phoneNoLength;
                                    const mobileInput = value?.replace(userSelectedCountry?.countryCode?.replace("+", ""), "");
                                    
                                    if (mobileInput.length === length) {
                                      const number = phoneUtil.parseAndKeepRawInput(value, country.iso2);
                                      if (phoneUtil.isValidNumber(number)) {
                                        setIsPhoneNumberValid(true);
                                        return true;                                        
                                      } else {
                                        setIsPhoneNumberValid(false);
                                        return 'Invalid mobile number: ' + value + ', ' + country.name;
                                      }
                                    } else if (mobileInput.length > length) {
                                      return 'Invalid mobile number: ' + value + ', ' + country.name;
                                    }
                                    return false;
                                  } catch (e) {
                                    return 'Invalid mobile number: ' + value + ', ' + country.name;
                                  }
                                }}
                              />
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="form-group">
                              <label htmlFor="registerEmail">Email Address<span>&nbsp;(Optional)</span></label>
                              <input type="email" name="registerEmail" id="registerEmail" className="form-control" placeholder="" value={email} onChange={handleChange} />
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="form-group">
                              <label htmlFor="registerPassword">Password<span style={{ color: "red" }}>&nbsp;*</span></label>
                              <input type="password" name="registerPassword" id="registerPassword" className="form-control" placeholder="" value={password} onChange={handleChange} />
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="form-group">
                              <label htmlFor="registerPasswordConfirm">Confirm Password<span style={{ color: "red" }}>&nbsp;*</span></label>
                              <input type="password" name="registerPasswordConfirm" id="registerPasswordConfirm" className="form-control" placeholder="" value={confirmPassword} onChange={handleChange} />
                            </div>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-center align-items-left" style={{ marginTop: 10.5 }}>
                          <Col md={9} xs={8}>
                            <label className="label-container checkbox-default" style={{ marginTop: 10.5 }}>
                              <span > I accept {" "} <a href="/terms_conditions">terms of use</a> and{" "} <a href="/privacy_policy">privacy policy</a> </span>
                              <input type="checkbox" onClick={acceptTerms} />
                              <span className="checkmark"></span>
                            </label>
                          </Col>
                          <Col md={3} xs={4}>
                            <input id="sign-up-button" type="submit" className="form-control rounded" value="SignUp" onClick={handleclick}
                              disabled={isButtonDisabled()}
                              style={{
                                backgroundColor: isButtonDisabled() && 'gray', cursor: isButtonDisabled() && 'no-drop'
                              }} />
                          </Col>
                        </Row>
                      </div>
                    </form>
                  }
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.Auth?.isAuthenticated
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signupSuccess: (user) => { dispatch(signupSuccess(user)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
