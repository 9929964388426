import React from "react";
import { Container, Row, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { connect } from "react-redux";
import UserSidebar from "./UserDashboardSidebar";
import alertify from "alertifyjs";
import axios from "axios";
import { ENDPOINT } from "../constants";
import { persister } from "../redux/store";

class Withdraw extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            full_name: '',
            account_number: '',
            bank_name: '',
            branch_code: '',
            branch_address: '',
            iban_number: '',
        };
    }
    componentDidMount(){
        window.scrollTo(0, 0);
    }

    handleWithdrawSubmit = async (e) =>{
        e.preventDefault();

        var account_number_el = document.getElementById('account_number');
        var bank_name_el = document.getElementById('bank_name');
        var branch_code_el = document.getElementById('branch_code');
        var branch_address_el = document.getElementById('branch_address');
        var iban_number_el = document.getElementById('iban_number');
        var amount_el = document.getElementById('amount');
        

        if(!/^[0-9]+$/.test(this.state.account_number)){
            if(this.state.account_number === ""){
                alertify.notify('Account number can not be empty', 'custom', 2, function(){});
              account_number_el.style.border = "1px solid #ff8476";
              return;
            }else{
                alertify.notify('Account number should only contain digits', 'custom', 2, function(){});
              account_number_el.style.border = "1px solid #ff8476";
              return;
            }
        }else{
            account_number_el.style.border = "1px solid #ddd";
        }

        if(!/^[a-zA-Z ]+$/.test(this.state.bank_name)){
            if(this.state.bank_name === ""){
                alertify.notify('Bank Name cannot be empty', 'custom', 2, function(){});
              bank_name_el.style.border = "1px solid #ff8476";
              return;
            }else{
                alertify.notify('Bank name should only contain alphabets', 'custom', 2, function(){});
              bank_name_el.style.border = "1px solid #ff8476";
              return;
            }
        }else{
            bank_name_el.style.border = "1px solid #ddd";
        }

        if(!/^[0-9]+$/.test(this.state.branch_code)){
            if(this.state.branch_code === ""){
                alertify.notify('Branch code cannot be empty', 'custom', 2, function(){});
              branch_code_el.style.border = "1px solid #ff8476";
              return;
            }else{
                alertify.notify('Branch code should only contain digits', 'custom', 2, function(){});
              branch_code_el.style.border = "1px solid #ff8476";
              return;
            }
        }else{
            branch_code_el.style.border = "1px solid #ddd";
        }

        if(!/^[#a-zA-Z0-9 ]+$/.test(this.state.branch_address)){
            if(this.state.branch_address === ""){
                alertify.notify('Branch address cannot be empty', 'custom', 2, function(){});
              branch_address_el.style.border = "1px solid #ff8476";
              return;
            }else{
                alertify.notify('Branch address should only contain digits, alphabets and characters', 'custom', 2, function(){});
              branch_address_el.style.border = "1px solid #ff8476";
              return;
            }
        }else{
            branch_address_el.style.border = "1px solid #ddd";
        }

        if(!/^[a-zA-Z0-9]+$/.test(this.state.iban_number)){
            if(this.state.iban_number === ""){
                alertify.notify('IBAN number cannot be empty', 'custom', 2, function(){});
              iban_number_el.style.border = "1px solid #ff8476";
              return;
            }else{
                alertify.notify('IBAN number should only contain digits, alphabets', 'custom', 2, function(){});
              iban_number_el.style.border = "1px solid #ff8476";
              return;
            }
        }else{
            iban_number_el.style.border = "1px solid #ddd";
        }

        if(!/^[0-9]+$/.test(this.state.amount)){
            if(this.state.amount === ""){
                alertify.notify('Amount cannot be empty', 'custom', 2, function(){});
              amount_el.style.border = "1px solid #ff8476";
              return;
            }else{
                alertify.notify('Amount should only contain number', 'custom', 2, function(){});
              amount_el.style.border = "1px solid #ff8476";
              return;
            }
        }else{
            amount_el.style.border = "1px solid #ddd";
        }
        
        const config = {
            headers: {
            'content-type': 'application/json',
            'Authorization': 'Bearer '+this.props.Auth?.token
            }
        };
        await axios.post(ENDPOINT+'/request-withdraw',{user_id: this.props.user.id, account_number: this.state.account_number, bank_name: this.state.bank_name, branch_code: this.state.branch_code, branch_address: this.state.branch_address, iban_number: this.state.iban_number, amount: this.state.amount},config)
        .then((response) => {
            if(response.data.code === 404){
                alertify.notify(response.data.message, 'custom', 2, function(){});
            }
    
            if(response.data.code === 200){
                alertify.notify(response.data.message, 'customsuccess', 2, function(){});
            }
        }).catch((error) => {
            alertify.notify(error.message, 'custom', 2, function(){});
            if(error.message === 'Request failed with status code 401'){
                persister.purge();
            }
        });
        
    }   

    handleChange = (e) =>{
        if(e.target.id === 'account_number'){
            this.setState({
                account_number: e.target.value
            });
        }
        if(e.target.id === 'bank_name'){
            this.setState({
                bank_name: e.target.value
            });
        }
        if(e.target.id === 'branch_code'){
            this.setState({
                branch_code: e.target.value
            });
        }
        if(e.target.id === 'branch_address'){
            this.setState({
                branch_address: e.target.value
            });
        }
        if(e.target.id === 'iban_number'){
            this.setState({
                iban_number: e.target.value
            });
        }
        if(e.target.id === 'amount'){
            this.setState({
                amount: e.target.value
            });
        }
    }
    
    render(){
        return (
            <>
                <section id="content" className="page-single bg-grey with-sidebar">
                    <div id="content-wrap">
                        {/* === Section Flat =========== */}
                        <div className="section-flat">
                        <div className="section-content">
                            <Container>
                            <Row>

                                {/* .col-md-12 end */}

                                <Col md={12}>
                                <div className="page-single-content sidebar-left">
                                    <Row>
                                        <Col lg={3} md={12} className="mb-4">
                                            <UserSidebar/>
                                        </Col>
                                        <Col lg={9} md={12}>
                                            <div className="content-main">
                                                <div className="block-content-2">
                                                    <div className="block-title">
                                                    <h3>
                                                        Request Withdraw
                                                    </h3>
                                                    </div>
                                                    {/* .block-title end */}
                                                    <Form id="form-hotel-booking" onSubmit={this.handleWithdrawSubmit}>
                                                    <div className="form-content">
                                                        <FormGroup>
                                                            <div className="fields-row fields-2">
                                                                <div className="box-field">
                                                                <Label for="hbFirstName">
                                                                    Account Number
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    name="hbFirstName"
                                                                    id="account_number"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={this.state.account_number}
                                                                    onChange={this.handleChange}
                                                                />
                                                                </div>
                                                                {/* .box-field end */}
                                                                <div className="box-field">
                                                                <Label for="hbLastName">
                                                                    Bank Name
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    name="hbLastName"
                                                                    id="bank_name"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={this.state.bank_name}
                                                                    onChange={this.handleChange}
                                                                />
                                                                </div>
                                                                {/* .box-field end */}
                                                            </div>
                                                            {/* .fields-row end */}
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <div className="fields-row fields-2">
                                                                <div className="box-field">
                                                                <Label for="hbFirstName">
                                                                    Branch Code
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    name="hbFirstName"
                                                                    id="branch_code"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={this.state.branch_code}
                                                                    onChange={this.handleChange}
                                                                />
                                                                </div>
                                                                {/* .box-field end */}
                                                                <div className="box-field">
                                                                <Label for="hbLastName">
                                                                    Branch Address
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    name="hbLastName"
                                                                    id="branch_address"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={this.state.branch_address}
                                                                    onChange={this.handleChange}
                                                                />
                                                                </div>
                                                                {/* .box-field end */}
                                                            </div>
                                                            {/* .fields-row end */}
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <div className="fields-row fields-2">
                                                                <div className="box-field">
                                                                <Label for="hbFirstName">
                                                                    IBAN Number
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    name="hbFirstName"
                                                                    id="iban_number"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={this.state.iban_number}
                                                                    onChange={this.handleChange}
                                                                />
                                                                </div>
                                                                <div className="box-field">
                                                                <Label for="hbFirstName">
                                                                    Amount
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    name="hbFirstName"
                                                                    id="amount"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={this.state.amount}
                                                                    onChange={this.handleChange}
                                                                />
                                                                </div>
                                                            </div>
                                                            {/* .fields-row end */}
                                                        </FormGroup>
                                                        
                                                        
                                                        
                                                        
                                                        <FormGroup>
                                                        <Input
                                                            type="submit"
                                                            className="form-control"
                                                            value="Request Withdraw"

                                                        />
                                                        </FormGroup>
                                                        {/* .form-group end */}
                                                    </div>
                                                    {/* .form-content end */}
                                                    </Form>
                                                    {/* #form-hotel-booking end */}
                                                </div>
                                                {/* .block-content-2 end */}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                {/* .page-single-content end */}
                                </Col>
                                {/* .col-md-12 end */}
                            </Row>
                            {/* .row end */}
                            </Container>
                            {/* .container end */}
                        </div>
                        {/* .section-content end */}
                        </div>
                        {/* .section-flat end */}
                    </div>
                    {/* #content-wrap */}
                </section>
            </>
        );
    }
}

const mapStateToProps = (state) =>{
    return {
      ...state
    }
}

export default connect(mapStateToProps, null)(Withdraw);