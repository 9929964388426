import baseCountryConfig from "./baseConfig";

const AO = {
  ...baseCountryConfig,
  countryName: "Angola",
  isoCode: "AO",
  countryCode: "+244",
  iconName: "angola_flag",
  languages: [{ name: "Portuguese", locale: "pt" }],
  locale: "pt-AO",
  currencyCode: "AOA",
  currencySymbol: "Kz",
  phoneRegExp: /^[0-9]{9}$/,
  phoneNoLength: 9,
  isCountryEnabled: false,
};

export default AO;
