/* AUTH_REFRESH */
export const REQUEST_REFRESH_JWT_TOKEN = "REQUEST_REFRESH_JWT_TOKEN";
export const RECEIVED_REFRESH_JWT_TOKEN = "RECEIVED_REFRESH_JWT_TOKEN";
export const FAILED_REFRESH_JWT_TOKEN = "FAILED_REFRESH_JWT_TOKEN";

export const ERROR_RECEIVED = "ERROR_RECEIVED";
export const ERROR_CANCELED = "ERROR_CANCELED";
export const LOADING_RECEIVED = "LOADING_RECEIVED";
export const LOADING_COMPLETED = "LOADING_COMPLETED";

/* Sign-in */
export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const RECEIVED_LOGIN = "RECEIVED_LOGIN";
export const FAILED_LOGIN = "FAILED_LOGIN";

/* Change Password*/
export const REQUEST_CHANGE_PASSWORD = "REQUEST_CHANGE_PASSWORD";
export const RECEIVED_CHANGE_PASSWORD = "RECEIVED_CHANGE_PASSWORD";
export const FAILED_CHANGE_PASSWORD = "FAILED_CHANGE_PASSWORD";
export const RESET_CHANGE_PASSWORD_STATE = "RESET_CHANGE_PASSWORD_STATE";

/* Reset Password */

export const REQUEST_DEFAULT_PASSWORD = "REQUEST_DEFAULT_PASSWORD";
export const RECEIVED_DEFAULT_PASSWORD = "RECEIVED_DEFAULT_PASSWORD";
export const FAILED_DEFAULT_PASSWORD = "FAILED_DEFAULT_PASSWORD";
export const CANCELED_DEFAULT_PASSWORD = "CANCELED_DEFAULT_PASSWORD";

export const REQUEST_SET_PASSWORD = "REQUEST_SET_PASSWORD";
export const RECEIVED_SET_PASSWORD = "RECEIVED__SET_PASSWORD";
export const FAILED_SET_PASSWORD = "FAILED_SET_PASSWORD";
export const CANCELED_SET_PASSWORD = "CANCELED_SET_PASSWORD";

export const REQUEST_GENERATE_PASSWORD = "REQUEST_GENERATE_PASSWORD";
export const RECEIVED_GENERATE_PASSWORD = "RECEIVED_GENERATE_PASSWORD";
export const FAILED_GENERATE_PASSWORD = "FAILED_GENERATE_PASSWORD";
export const CANCELED_GENERATE_PASSWORD = "CANCELED_GENERATE_PASSWORD";

export const DESTROY_SESSION = "DESTROY_SESSION";

/** Bookings */

export const UPDATE_BUS_ROUTE_ID = "UPDATE_BUS_ROUTE_ID";
export const UPDATE_BUS_BUS_BOOKING_SEATS = "UPDATE_BUS_BUS_BOOKING_SEATS";
export const UPDATE_BUS_BOOKING_DATA = "UPDATE_BUS_BOOKING_DATA";
export const RECENT_BOOKING_DATA = "RECENT_BOOKING_DATA";
export const UPDATE_WALLET_BALANCE = "UPDATE_WALLET_BALANCE"

/** Search */

export const UPDATE_SEARCH_FIELDS = "UPDATE_SEARCH_FIELDS";

/** Profile */
export const PROFILE_UPDATE = "PROFILE_UPDATE";

/** Global */
export const REDIRECT_TO_RECENT_URL = "REDIRECT_TO_RECENT_URL";

/* User Details*/
export const REQUEST_USER_DETAILS = "REQUEST_USER_DETAILS";
export const RECEIVED_USER_DETAILS = "RECEIVED_USER_DETAILS";
export const FAILED_USER_DETAILS = "FAILED_USER_DETAILS";

/* User City Details*/
export const REQUEST_CITY_DETAILS = "REQUEST_CITY_DETAILS";
export const RECEIVED_CITY_DETAILS = "RECEIVED_CITY_DETAILS";
export const FAILED_CITY_DETAILS = "FAILED_CITY_DETAILS";
