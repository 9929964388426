import baseCountryConfig from "./baseConfig";

const NA = {
  ...baseCountryConfig,
  countryName: "Namibia",
  isoCode: "NA",
  countryCode: "+264",
  iconName: "namibia_flag",
  locale: "en-NA",
  currencyCode: "NAD",
  currencySymbol: "N$",
  phoneRegExp: /^[0-9]{9}$/,
  phoneNoLength: 9,
};

export default NA;
