import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Form, FormGroup } from "reactstrap";
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios from "axios";
import alertify from "alertifyjs";
import { connect } from "react-redux";
import { updateSearchFields } from "../actions/searchActions";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Footer from "../components/footers/Footer";
import Header from "../components/headers/Header";
import CustomNavbar from "../components/navbars/CustomNavbar";
import moment from 'moment';
import { searchBuses } from "../sagas/global";
import { actions } from "../actions";
import { firebaseDatabase } from "../config/firebaseConfig";
import { ref, onValue} from "firebase/database";
import { createBrowserHistory } from 'history';

const instance = axios.create();
instance.interceptors.request.use(function (response) {
  return response;
}, function (error) {
  if (error.response && error.response.data) {
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error.message);
});
const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function Search ({
  cities,
  from_cities,
  to_cities,
  selectedFromCity,
  selectedToCity,
  searchDate,
  updateSearchFields, 
  updateBusRouteId, 
  fetchSupportedCities,
  token
}){
  const [loading, setLoading] = useState(true);
  const [numberOfSearchResult, setNumberOfSearchResult] = useState(0);
  const [availableResults, setAvailableResults] = useState([]);
  const [nothingAvailable, setNothingAvailable] = useState(true);
  const [bookedBusSeats, setBookedBusSeats] = useState([]);
  const [selected_from_city, setSelectedFromCity] = useState(selectedFromCity || {});
  const [selected_to_city, setSelectedToCity] = useState(selectedToCity || {});
  const [date, setDate] = useState(null);
  const history = createBrowserHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    if(searchDate){
      setDate(searchDate)
    }
  }, []);

  useEffect(() => {
    try{
      setBookedBusSeats([])
      const timestamp = moment(date).format("YYYY-MM-DD").valueOf()
      const reference = "bookings/".concat(timestamp);
      onValue(ref(firebaseDatabase, reference), (snapshot) => {
        const data = snapshot.val();
        if(data !== null){
          let busSeats = [];
          const keys = Object.keys(data); //buses
          for(let i = 0; i < keys.length; i++){
            let seats = [];
            const innerKeys = Object.keys(data[keys[i]]); //seats
            for(let j = 0; j < innerKeys.length; j++){
              const innerKey = innerKeys[j];
              seats.push(data[keys[i]][innerKey])
            }
            busSeats.push({
              busId: keys[i],
              seats: seats
            });
          }
          setBookedBusSeats(busSeats)
        }
      });
    } catch (_error) {
    }
  }, [date]);

  useEffect(() => {
    const fetchData = async () => {
      if(!cities?.details){
        fetchSupportedCities();
      }
      try{
        if(searchDate){
          const response = await searchBuses(moment(searchDate).format('YYYY-MM-DD').valueOf(), selected_from_city.value, selected_to_city.value);
          const { data = [], success } = response?.data;
          if (success) {
            handleRouteChange(data, searchDate);
          }
        }
      } catch (_error) {
      }finally{
        setLoading(false)
      }
    }
 
    fetchData();
  }, [cities]);

  const handleRouteChange = async (data, _date) =>{
    if(data?.length <= 0){
      setNothingAvailable(true);
    }else{
      setNothingAvailable(false);
      setNumberOfSearchResult(data?.length)
      setAvailableResults(data);
    }
    setLoading(false)
  }

  const handleFromCitySelectChange = (option) => {
    if(selected_to_city !== undefined && selected_to_city !== null){
      if(selected_to_city.value === option.value){
        alertify.error('Departure and Destination place can not be the same');
      } else{
        setSelectedFromCity({ value: option.value, label: option.label });
      }
    } else{
      setSelectedFromCity({ value: option.value, label: option.label });
    }
  };

  const handleToCitySelectChange = (option) => {
    if(selected_from_city !== undefined && selected_from_city !== null){
      if(selected_from_city.value === option.value){
        alertify.error('Departure and Destination place can not be the same');
      } else{
        setSelectedToCity({ value: option.value, label: option.label });
      }
    } else{
      setSelectedToCity({ value: option.value, label: option.label });
    }
  };
  
  const handleDateChange = (e) => {
    setDate(e);
  };

  const handleBookingBtn = (result) => {
    const data = {
      allowedTime: result?.bus?.allowedTime,
      busTypeId: result?.busType?.uuid,
      company: result?.bus?.company,
      image: result?.bus?.image,
      lastSeat: result?.bus?.lastSeat,
      layout: result?.bus?.layout,
      noOfRows: result?.bus?.noOfRows,
      owner: result?.bus?.owner,
      partnerId: result?.owner?.uuid,
      regNo: result?.bus?.regNo,
      totalSeats: result?.bus?.totalSeats,
      uuid: result?.bus?.uuid,
      operatorName: result?.owner?.name,
      busRouteId: result?.routeDetails?.busSubRoute[0]?.busSubRoute?.busRouteId,
      busSubRouteId: result?.routeDetails?.busSubRoute[0]?.busSubRoute?.uuid,
      busTypeName: result?.busType?.name,
      arrivalTime: result?.routeDetails?.busRoute?.arrival,
      departureStop: result?.routeDetails?.busSubRoute[0]?.subRoute?.starting,
      departureTime: result?.routeDetails?.busRoute?.departure,
      destinationStop: result?.routeDetails?.busSubRoute[0]?.subRoute?.ending,
      distance: result?.routeDetails?.busSubRoute[0]?.busSubRoute?.distance,
      isMain: result?.routeDetails?.busSubRoute[0]?.subRoute?.isDefault === true,
      routeName: result?.routeDetails?.busSubRoute[0]?.subRoute?.name,
      price: result?.routeDetails?.busSubRoute[0]?.busSubRoute?.fare,
      time: result?.routeDetails?.busSubRoute[0]?.subRoute?.approximateTime,
      departureSequence: result?.routeDetails?.busSubRoute[0]?.subRoute?.departureSequenceNumber,
      arrivalSequence: result?.routeDetails?.busSubRoute[0]?.subRoute?.arrivalSequenceNumber
    }
    updateBusRouteId(result);
    history.push({
      pathname: "/user/booking",
      search: window.location.search,
    });
    window.location.reload();
  };
  
  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    let updatedFields = {
      from: selected_from_city,
      to: selected_to_city,
      date: date,
    };
    setNumberOfSearchResult(0)
    setAvailableResults([]);
    setNothingAvailable(true);

    updateSearchFields(updatedFields);
    try{
      const response = await searchBuses(moment(date).format('YYYY-MM-DD').valueOf(), selected_from_city.value, selected_to_city.value);
      const { data = [], success } = response?.data;
      if (success) {
        handleRouteChange(data, date);
      }
    } catch (_error) {
    }finally{
      setLoading(false)
    }
  };

  const renderSvg = (name) =>{
    try {
      return require(`../assets/images/files/${name}.svg`)
    } catch (err) {
      return null;
    }   
  }

  const renderTime = (time)=>{
    const hours = Math.floor(time /60)
    const minutes = time % 60
    if(hours && hours > 0){
      if(minutes && minutes > 0){
        return `${hours} hr(s) ${minutes} mins`
      }else{
        return `${hours} hr(s)`
      }      
    }else{
      if(minutes && minutes > 0){
        return `${minutes} mins`
      }else{
        return `-`
      }
    }
  }

  const render = () =>{
    let _availableResults = availableResults?.map(function(result, key){
      let busBookedSeats = bookedBusSeats.filter(bus=> bus?.busId === result?.bus?.uuid)
      if(busBookedSeats.length > 0){
        busBookedSeats = bookedBusSeats?.seats?.filter(seat=> {
          if(seat?.departureSequence === result?.routeDetails?.busSubRoute[0]?.subRoute?.departureSequenceNumber){
            return true;
          }
          return seat?.arrivalSequence >= result?.routeDetails?.busSubRoute[0]?.subRoute?.departureSequenceNumber
        });
        if(busBookedSeats?.length > 0){
          busBookedSeats = busBookedSeats[0]?.seats
        }
      }
      const busFacilities = result?.bus?.Facilities?.map((data, key )=>{
        return data.name;
      });
      return(
        <div key={key} className="route-inner p-3 bg-white mb-3">
          <Row className="p-2">
            <Col lg={2} className="p-0">
              <div className="identity-outer">
                <div className="identity-logo">
                  {result?.bus?.image && <img src={new Buffer.from(result?.bus.image).toString("ascii")} alt="" />}
                </div>
                <div className="identity-bus">
                  <img src={require('../assets/images/files/bus.svg')} alt="" />
                </div>
              </div>
            </Col>
            <Col lg={8} className="p-0">
              <div className="route-name">
                <Row>
                  <Col md={4}>
                    <div className="main-departure-outer">
                      <div className="icon-1 icon-1-svg"><img src={require('../assets/images/files/bus.svg')} alt="" /></div>
                      <div className="departure">
                        <div className="departure-time-text">Bus Reg. No</div>
                        <div className="departure-time-integer">{result?.bus.regNo}</div>
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="main-departure-outer for-line">
                      <div className="icon-1 icon-1-svg"><img src={require('../assets/images/files/route_coral.svg')} alt="" /></div>
                      <div className="departure">
                        <div className="departure-time-text">Features</div>
                        <div className="departure-time-integer">
                          {busFacilities?.length > 0 && (busFacilities?.map((data, key )=>
                            <OverlayTrigger key={key} placement='right' overlay={<Tooltip id={`tooltip-${key}`}> {data} </Tooltip>} >
                              {data && renderSvg(data?.toLowerCase()) ?
                                <span className="icon-1 icon-1-svg">
                                  <img src={renderSvg(data?.toLowerCase())} alt={data} />
                                </span>: <span className="icon-1 icon-1-svg" style={{fontSize: "10px"}}> {data?.toLowerCase()}</span>
                              }
                            </OverlayTrigger>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="main-departure-outer for-line">
                      <div className="icon-1 icon-1-svg"><img src={require('../assets/images/files/bus/seat-available.png')} alt="" /></div>
                      <div className="departure">
                        <div className="departure-time-text">Free Seats</div>
                        <div className="departure-time-integer">{(parseInt(result?.bus?.totalSeats) - (busBookedSeats?.length || 0))}{"/"}{parseInt(result?.bus?.totalSeats)} Seats</div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="route-detail">
                <Row>
                  <Col md={4}>
                    <div className="main-departure-outer">
                      <div className="icon-1 icon-1-svg"><img src={require('../assets/images/files/clock.svg')} alt="" /></div>
                      <div className="departure">
                        <div className="departure-time-text">Departure Time</div>
                        <div className="departure-time-integer">{result?.routeDetails?.busRoute?.departure}Hrs</div>
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="main-departure-outer for-line">
                      <div className="icon-1 icon-1-svg"><img src={require('../assets/images/files/clock.svg')} alt="" /></div>
                      <div className="departure">
                        <div className="departure-time-text">Duration</div>
                        <div className="departure-time-integer">{renderTime(result?.routeDetails?.busSubRoute[0]?.busSubRoute?.approximateTime)}</div>
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="main-departure-outer for-line">
                      <div className="icon-1 icon-1-svg"><img src={require('../assets/images/files/pin.svg')} alt="" /></div>
                      <div className="departure">
                        <div className="departure-time-text">Type</div>
                        <div className="departure-time-integer">{result?.busType?.name}</div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={2} className="p-0">
              <div className="curency">BWP {result?.routeDetails?.busSubRoute[0]?.busSubRoute?.fare}</div>
              <div className="sold">
                <div className="seats-left">Price Per Person</div>
                <a href="#" onClick={()=> handleBookingBtn(result)} className="sold-1">Book Now</a>
              </div>
            </Col>
          </Row>
        </div>
      );
    },this)

    return (
      <>
        <Header />
        <CustomNavbar />
        <section id="content" className="page-search">
          <div id="content-wrap">
            {/* === Section Flat =========== */}
            <div className="section-flat">
              <div className="section-content">
                <Container>
                  <Row>
                    <Col className="search-box" xl={4}>
                      <Row>
                        <Col md={12} className="mb-3 ">
                          <div className="result-found-outer">
                            <div className="result-found-inner">
                              <div className="result-found-icon"><img src={require('../assets/images/files/search.svg')} alt="" /></div>
                              <div className="result-found-text">{numberOfSearchResult} results found</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Form className="form-banner-reservation br-tabs-content form-inline style-2 form-h-50 mb-4">
                        <Row>                          
                          <Col md={12} className="mb-3 ">
                            <FormGroup>
                              <Select
                                defaultValue={selected_from_city}
                                isLoading={loading}
                                onChange={handleFromCitySelectChange}
                                isSearchable={true}
                                options={from_cities}
                              />
                              <i className="fas fa-plane rotate-up"></i>
                            </FormGroup>
                          </Col>
                          <Col md={12} className="mb-3">
                            <FormGroup>
                              <Select
                                style={{ width: "100%" }}
                                defaultValue={selected_to_city}
                                isLoading={loading}
                                onChange={handleToCitySelectChange}
                                isSearchable={true}
                                options={to_cities}
                              />
                              <i className="fas fa-plane rotate-down"></i>
                            </FormGroup>
                          </Col>
                          <Col md={12} className="mb-3">
                            <FormGroup className="search-date-picker">
                              <DatePicker
                                selected={date}
                                onChange={handleDateChange}
                                minDate={new Date()}
                                className="riding-date riding-date-search"
                              />
                              <i className="far fa-calendar"></i>
                            </FormGroup>
                          </Col>
                          <Col lg={12} md={12}>
                            <FormGroup>
                              <Button
                                type="submit"
                                className="form-control icon"
                                onClick={handleSearch}
                              > Search Again
                                <i className="fas fa-search"></i>
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                    <Col xl={8}>
                      <Row>
                        <Col xl={12}>
                          <div className="search-outer search-outer-2 mb-4">
                            <div className="search-inner">
                              <div className="search-heading ">Your Search</div>
                              <div className="search-location">
                                <div className="search-from mr-5">
                                  <div className="search-label"><b> From:{" "} </b></div>
                                  <div className="search-label-content">{' ' + selected_from_city?.label || "" }</div>
                                </div>
                                <div className="search-to mr-5">
                                  <div className="search-label"><b> To:{' '} </b></div>
                                  <div className="search-label-content">{' ' + selected_to_city?.label  || ""}</div>
                                </div>
                                <div className="search-date">
                                  <div className="search-label"><b> Date:{' '} </b></div>
                                  <div className="search-label-content">{' ' + month[date?.getMonth()] + ' '+ date?.getDate() + ', ' + date?.getFullYear()}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {loading ? 
                      <div className="row">
                        <Col md={12}>
                          <div className="content-main" id="bus-listing">
                            <div class="ring" style={{ marginTop: 150, padding: 0, background: "#262626" }}>Searching <span class="span"></span></div>
                          </div>
                        </Col>
                      </div>:
                      <div className="page-single-content sidebar-left">
                        {nothingAvailable ? 
                          <div className="no-bus-outer pl-5 pt-5 pb-4">
                            <div className="no-bus-inner">
                              <div className="no-bus-img pr-5"><img src={require('../assets/images/files/no_bus.png')} alt="" /></div>
                              <div className="main-bus-text">
                                <div className="no-bus-text">No buses available for route</div>
                                <div className="no-bus-text-1">Search again</div>
                              </div>
                            </div>
                          </div>
                          :
                          <Row ><Col md={12}><div className="route-outer">{_availableResults}</div></Col></Row>
                        }
                        <div className="row"><Col md={12}><div className="content-main" id="bus-listing"></div></Col></div>
                      </div>}
                    </Col>
                  </Row>
                  <Row></Row>
                </Container>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }

  return (render())
}

const mapStateToProps = (state) => {
  return {
    token: state?.Auth?.token,
    from_cities: state?.cities?.details,
    to_cities: state?.cities?.details,
    selectedFromCity: state?.global.search_from,
    selectedToCity: state?.global.search_to,
    searchDate: new Date(state?.global.search_date),
    cities: state?.cities
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSearchFields: (data) => {
      dispatch(updateSearchFields(data));
    },
    updateBusRouteId: (data) => {
      dispatch(actions.booking.updateBusRouteId(data));
    },
    fetchSupportedCities: () => {
      dispatch(actions.cities.fetchCities());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
